import { StaticDataCustomerLabelsType, StaticDataItemType } from 'src/models'

import { staticDataKeys, staticDataService } from 'src/bus/staticData'

import { useQueryAsync } from 'src/hooks'

type UseStaticDataCustomerLabelsFetchType = {
  loadingStaticDataCustomerLabelsFetch: boolean
  staticDataCustomerLabels: { customerLabels: StaticDataItemType[] }
}

export const useStaticDataCustomerLabelsFetch = (
  businessTypeId?: string,
  marketTypeId?: string,
): UseStaticDataCustomerLabelsFetchType => {
  const params = { marketTypeId, businessTypeId }

  const fetchStaticDataCustomerLabelsProps = useQueryAsync<StaticDataCustomerLabelsType>({
    key: staticDataKeys.customerLabels(businessTypeId, marketTypeId),
    name: 'fetchStaticDataCustomerLabels',
    fetcher: staticDataService.fetchStaticDataCustomerLabels,
    fetcherPayload: params,
  })

  return {
    loadingStaticDataCustomerLabelsFetch: fetchStaticDataCustomerLabelsProps.isLoading,
    staticDataCustomerLabels: { customerLabels: fetchStaticDataCustomerLabelsProps.data?.['customer-labels'] || [] },
  }
}
