import { useQueryClient } from '@tanstack/react-query'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { ConvertCodeType } from 'src/models'

import { userOptionsKeys, userOptionsService, useScannerCode } from 'src/bus/userOptions'

import { useQueryAsync } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

export const useConvertCodeFetch = () => {
  const queryClient = useQueryClient()
  const { setOnPageDialog } = useDialog()
  const { code, updateCode } = useScannerCode()

  const convertCodeProps = useQueryAsync<ConvertCodeType>({
    key: userOptionsKeys.convertedCode(),
    name: 'convertCode',
    fetcher: userOptionsService.convertCode,
    fetcherPayload: { code },
    options: { enabled: !!code },
  })

  useDeepCompareEffect(() => {
    if (convertCodeProps.isError) {
      setOnPageDialog(GET_ENUMS.dialog.info, true)
      updateCode('')
      queryClient.removeQueries({ queryKey: userOptionsKeys.convertedCode() })
    }
  }, [convertCodeProps.isError, setOnPageDialog, queryClient])

  return {
    convertCode: convertCodeProps.data,
    loadingConvertCodeFetch: convertCodeProps.isLoading,
  }
}
