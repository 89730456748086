import { AxiosPromise } from 'axios'
import { z } from 'zod'

export enum HttpStatusCode {
  success = 200,
  created = 201,
  noContent = 204,
  badRequest = 400,
  auth = 401,
  forbidden = 403,
  notFound = 404,
  unprocessableEntity = 422,
  toMuchReq = 429,
  cancelled = 499,
  server = 500,
  serviceUnavailable = 503,
}

export enum HttpStatusMessage {
  unauthenticated = 'Unauthenticated.',
}

export const serverErrorBaseSchema = z.object({
  type: z.string(),
  message: z.string(),
})

export type ServerErrorBaseType = z.infer<typeof serverErrorBaseSchema>

export type ServerErrorFieldDetailsType = { alias: string; desc: string }
export type ServerErrorFieldFormatType = Record<string, string>
export type ServerErrorValidationFieldFormatType = Record<string, ServerErrorFieldDetailsType>

export type ServerErrorParsedFieldFormatType = Record<string, string>

export type HttpErrorFieldFormatType = ServerErrorBaseType & {
  errors?: ServerErrorFieldFormatType
}

export type ServerErrorFormatType = ServerErrorBaseType & {
  errors?: ServerErrorFieldFormatType
}

export type ServerErrorRawFormatType = ServerErrorBaseType & {
  errors?: ServerErrorValidationFieldFormatType
  hasError?: boolean
}

export type ServerErrorParsedFormatType = ServerErrorBaseType & {
  errors?: ServerErrorParsedFieldFormatType
}

export type HttpResponseError = ServerErrorFormatType & {
  error: ServerErrorFormatType
  status?: HttpStatusCode
}

// type HttpEntity<T> = {
//   data: T;
//   messages: HttpErrorType[];
// };
//
// export type HttpErrorType = {
//   type?: string;
//   title: string;
//   desc: string;
// };

export type HttpErrorType = ServerErrorFormatType & {
  status: HttpStatusCode
}

export type ApiErrorType = {
  error: ServerErrorFormatType
  status: HttpStatusCode
  data?: any
}

export type HttpServerRequestParams<D = unknown, P = Record<string, string | string[] | object | number>> = {
  headers?: Record<string, string>
  params?: P
  data?: D
}

export type HttpRequestParams<T = object> = {
  id?: number
  params?: {
    id?: number
    page?: number
    size?: number
    filter?: {
      search?: string
      dateFrom?: string
      dateTo?: string
    }
  }
  data?: T
}

export type HttpResponse<T = { data: { data: unknown }; status?: number }> = {
  data: T
  status: HttpStatusCode
}

export type FetchDataType<T, P = any> = (v: P) => AxiosPromise<HttpResponse<T>>
