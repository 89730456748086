import { CouponType } from 'src/models'

import { couponsKeys, couponsState, couponsService } from 'src/bus/coupons'

import { useQueryAsync } from 'src/hooks'

type UseCouponsFetchType = {
  loadingCouponsFetch: boolean
  coupons: CouponType | null
}

export const useCouponsFetch = (visitId?: number): UseCouponsFetchType => {
  const params = { visitId }

  const fetchCouponsProps = useQueryAsync<CouponType>({
    key: couponsKeys.coupons(visitId),
    name: 'fetchCoupons',
    fetcher: couponsService.fetchCoupons,
    fetcherPayload: params,
    options: {
      enabled: !!visitId,
      refetchOnMount: 'always', // Get fresh data all the time
    },
  })

  return {
    loadingCouponsFetch: fetchCouponsProps.isLoading,
    coupons: fetchCouponsProps.data || couponsState.coupons,
  }
}
