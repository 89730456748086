import type { TFunction } from 'i18next'

import {
  CellText,
  CellLink,
  CellProgressBar,
  CellPhonesTitle,
  CellPhonesValue,
  CellCopy,
  CellTextEdit,
  CellLocation,
  CellActiveCoupon,
  CellCustomerAddress,
  CellStatus,
} from 'src/components/common'

import { CustomerType, KamCustomerType } from 'src/models'

import { createCustomerPhone, getTranslate, MARKET_TYPE_KAM } from 'src/utils'

type CustomerModelProps = {
  data: CustomerType | null
  kamData: KamCustomerType | null
  loadingFetch: boolean
  visitId?: number
  t: TFunction
}

export const getCustomerModel = ({ data, kamData, loadingFetch, t, visitId }: CustomerModelProps) => {
  const getCustomersT = getTranslate('customers', t)

  const customerName = `${getCustomersT('customer_name')}: ${data?.title}`
  const awsValue = `${t('customers:customer_id')}: ${data?.awsId}`
  const crmValue = `${t('customers:crm_id')}: ${data?.crmId}`

  const copyAwsValue = `${customerName}\n${awsValue}`
  const copyCrmValue = `${customerName}\n${crmValue}`

  const AwsId = () =>
    CellCopy({
      value: data?.awsId,
      disabled: !data?.awsId,
      copiedText: 'customer_id_copied',
      copyValue: copyAwsValue,
      loadingFetch,
    })
  const CrmId = () =>
    CellCopy({
      value: data?.crmId,
      disabled: !data?.crmId,
      copiedText: 'crm_id_copied',
      copyValue: copyCrmValue,
      loadingFetch,
    })
  const Status = () => CellStatus({ value: data?.status || null, loadingFetch })
  const VatNumber = () => CellText({ value: data?.vat, loadingFetch })
  const ActiveCoupon = () => CellActiveCoupon(visitId)
  const Phone = () =>
    CellLink({
      value: createCustomerPhone({
        phonePrefix: data?.phonePrefix,
        phoneNumber: data?.phoneNumber,
      }),
      type: 'tel',
      loadingFetch,
    })
  const Email = () => CellLink({ value: data?.email, type: 'mailto', loadingFetch })
  const Note = () => CellTextEdit({ value: data?.notes, loadingFetch })
  const Address = () => CellCustomerAddress({ value: data?.address, loadingFetch })
  const RealLocation = () => CellLocation({ value: data?.realLocation, customerId: data?.id, loadingFetch })
  const Business = () => CellText({ value: data?.businessType?.label, loadingFetch })
  const Label = () => CellText({ value: data?.customerLabel?.label, loadingFetch })
  const Employees = () => CellText({ value: data?.quantityEmployees?.label, loadingFetch })
  const Loyalty = () => CellProgressBar({ value: data?.loyaltyProgram || null, loadingFetch })
  const MarketType = () => CellText({ value: data?.marketType?.label, loadingFetch })
  const CustomerGroup = () => CellText({ value: kamData?.group?.label, loadingFetch })
  const CustomerShortName = () => CellText({ value: kamData?.customerGroupName, loadingFetch })
  const SubGroupType = () => CellText({ value: kamData?.subGroupType?.label, loadingFetch })
  const GroupSize = () => CellText({ value: kamData?.groupSize, loadingFetch })
  const ContractNumber = () => CellText({ value: kamData?.contract, loadingFetch })

  const contactPersons =
    data?.contactPersons.map((item) => {
      const Name = () => CellText({ value: item.name, loadingFetch })
      const PhonesTitle = () => CellPhonesTitle({ title: getCustomersT('phone'), value: item.phones })
      const PhonesValue = () => CellPhonesValue({ value: item.phones, loadingFetch })
      const Position = () => CellText({ value: item.position, loadingFetch })

      return {
        id: item.id,
        titleName: getCustomersT('contact_person'),
        componentPerson: Name,
        phonesTitle: PhonesTitle,
        phonesValue: PhonesValue,
        positionTitle: getCustomersT('position'),
        position: Position,
      }
    }) || []

  return {
    customerInfo: [
      {
        id: 1,
        title: getCustomersT('customer_id'),
        component: AwsId,
      },
      {
        id: 2,
        title: getCustomersT('crm_id'),
        component: CrmId,
      },
      {
        id: 3,
        title: getCustomersT('status'),
        component: Status,
      },
      {
        id: 4,
        title: getCustomersT('loyalty_program'),
        component: Loyalty,
        hidden: data?.marketType?.value === MARKET_TYPE_KAM,
      },
      {
        id: 5,
        title: getCustomersT('vat'),
        component: VatNumber,
      },
      {
        id: 6,
        title: getCustomersT('active_coupon'),
        component: ActiveCoupon,
      },
      {
        id: 7,
        title: getCustomersT('note'),
        component: Note,
      },
      {
        id: 8,
        title: getCustomersT('real_location'),
        component: RealLocation,
      },
      {
        id: 9,
        title: getCustomersT('market_type'),
        component: MarketType,
        hidden: data?.marketType?.value === MARKET_TYPE_KAM,
      },
      {
        id: 10,
        title: getCustomersT('business_type'),
        component: Business,
      },
      {
        id: 11,
        title: getCustomersT('label'),
        component: Label,
      },
      {
        id: 12,
        title: getCustomersT('quantity_employees'),
        component: Employees,
      },
      {
        id: 13,
        title: getCustomersT('phone'),
        component: Phone,
      },
      {
        id: 14,
        title: getCustomersT('email'),
        component: Email,
      },
      {
        id: 15,
        title: getCustomersT('address'),
        component: Address,
      },
    ],
    marketInfo: [
      {
        id: 1,
        title: getCustomersT('market_type'),
        component: MarketType,
      },
      {
        id: 2,
        title: getCustomersT('customer_group'),
        component: CustomerGroup,
      },
      {
        id: 3,
        title: getCustomersT('customer_short_name'),
        component: CustomerShortName,
      },
      {
        id: 4,
        title: getCustomersT('sub_group_type'),
        component: SubGroupType,
      },
      {
        id: 5,
        title: getCustomersT('group_size'),
        component: GroupSize,
      },
      {
        id: 6,
        title: getCustomersT('contract_number'),
        component: ContractNumber,
      },
    ],
    contactPersons,
  }
}
