import { useTranslation } from 'next-i18next'
import React, { forwardRef } from 'react'
import Select, {
  Props,
  StylesConfig,
  OptionProps,
  components as selectComponents,
  SingleValueProps,
  MultiValueProps,
  DropdownIndicatorProps,
  SelectInstance as ReactSelectInstance,
  ControlProps,
  NoticeProps,
} from 'react-select'
import makeAnimated from 'react-select/animated'
import { cn } from 'utils/helpers'

import { SelectItem } from 'src/models'

import { messages, orNull, ternary } from 'src/utils'

import { Icon } from '../../Icon'
import { Typography } from '../../Typography'

import st from './Styles.module.css'

type TPosition = 'absolute' | 'relative'

export type InputSelectProps = Props<SelectItem> & {
  hasDisabled?: boolean
  loading?: boolean
  hasError?: boolean
  prefixIcon?: string
  size?: 'large' | 'middle' | 'small'
  isFilter?: boolean
  position?: TPosition
}

type ControlPropsType = ControlProps<SelectItem>
type SelectItemValueProps = SingleValueProps<SelectItem>
type SelectItemMultiValueProps = MultiValueProps<SelectItem>
type SelectItemOptionProps = OptionProps<SelectItem>
type SelectIndicatorProps = DropdownIndicatorProps<SelectItem> & {
  loading?: boolean
}

const components = {
  ...selectComponents,
  ...makeAnimated(),
}

const ControlItem = ({ children, ...rest }: ControlPropsType) => {
  const Control = components.Control as React.ComponentType<ControlPropsType>
  return <Control {...rest}>{children}</Control>
}

const SelectItemValue = (props: SelectItemValueProps) => {
  const { data } = props
  const SingleValue = components.SingleValue as React.ComponentType<SelectItemValueProps>

  return (
    <SingleValue {...props} className={st['select-item']}>
      {data?.icon && <Icon name={data.icon} />} {data?.label}
    </SingleValue>
  )
}

const SelectItemMultiValue = (props: SelectItemMultiValueProps) => {
  const { data } = props
  const MultiValue = components.MultiValue as React.ComponentType<SelectItemMultiValueProps>

  return (
    <MultiValue {...props}>
      {data?.icon && <Icon name={data.icon} />} {data?.label}
    </MultiValue>
  )
}

const SelectIndicator = (props: SelectIndicatorProps) => {
  const { selectProps, loading } = props
  const DropdownIndicator = components.DropdownIndicator as React.ComponentType<SelectIndicatorProps>

  return (
    <DropdownIndicator {...props}>
      {ternary(
        loading,
        <Icon rotate name='Loading' color='blue-gray-85' width={20} height={20} />,
        <>
          {ternary(
            selectProps.menuIsOpen,
            <Icon name='ChevronUp' color='blue-gray-85' width={20} height={20} />,
            <Icon name='ChevronDown' color='blue-gray-85' width={20} height={20} />,
          )}
        </>,
      )}
    </DropdownIndicator>
  )
}

const SelectItemOption = (props: SelectItemOptionProps) => {
  const { data } = props
  const Option = components.Option as React.ComponentType<any>

  return (
    <Option {...props}>
      <div className='overflow-hidden'>
        {orNull(data?.icon, <Icon name={data?.icon} color='blue-gray-85' />)}
        <Typography variant='secondary'>{data?.label}</Typography>
      </div>
    </Option>
  )
}

const colorStyles = (isError: boolean, isFilter: boolean, position: TPosition): StylesConfig<any> => ({
  container: (styles) => {
    return {
      ...styles,
      border: '1px solid transparent',
    }
  },
  control: (styles, { menuIsOpen, isDisabled, isFocused }) => {
    const generalStyles = {
      ...styles,
      borderRadius: '2px',
      fontSize: '14px',
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      border: isFocused || menuIsOpen ? '1px solid #F9B083' : isError ? '1px solid #f64f00' : '1px solid #d0d3d6',
      ':hover': {
        borderColor: '#F9B083',
      },
    }

    return isFilter
      ? {
          ...generalStyles,
        }
      : {
          ...generalStyles,
          minHeight: '30px',
          height: '30px',
          color: '#132530',
          backgroundColor: isDisabled ? '#b9bec1' : '#ffffff',
        }
  },
  singleValue: (styles) => {
    return {
      ...styles,
      color: '#132530',
      fontSize: '14px',
      lineHeight: '20px',
    }
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#FAFBFB',
      border: '1px solid #E7E9EA',
      borderRadius: '4px',
    }
  },
  multiValueLabel: (styles) => {
    return {
      ...styles,
      fontSize: '12px',
      lineHeight: '22px',
      padding: '0',
    }
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      padding: '0 8px',
      height: '28px',
    }
  },
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      height: '28px',
      // pointerEvents: "none",
    }
  },
  indicatorSeparator: (styles) => {
    return {
      ...styles,
      color: '#f1f4f6',
      margin: '7px 0',
    }
  },
  menu: (styles) => {
    return {
      ...styles,
      boxShadow: isFilter ? 'none' : '0px 2px 4px rgba(19, 37, 48, 0.12), 0px 0px 8px rgba(19, 37, 48, 0.16)',
      borderRadius: '2px',
      position: isFilter || position === 'relative' ? 'relative' : 'absolute',
      maxHeight: isFilter || position === 'relative' ? 205 : 'auto',
      overflow: isFilter || position === 'relative' ? 'auto' : 'visible',
    }
  },
  menuList: (styles) => {
    return isFilter
      ? {
          ...styles,
          padding: 0,
          maxHeight: 'none',
        }
      : {
          ...styles,
          padding: '8px',
        }
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return isFilter
      ? {
          ...styles,
          padding: '15px',
          fontSize: '14px',
          lineHeight: '22px',
          backgroundColor: 'unset',
          boxShadow: 'inset 0 -1px 0 #E5EAED',
        }
      : {
          ...styles,
          padding: '8px',
          fontSize: '14px',
          lineHeight: '22px',
          color: '#132530',
          backgroundColor: isDisabled ? undefined : isSelected ? '#f1f4f6' : isFocused ? '#ffffff' : undefined,
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled ? (isSelected ? '#f1f4f6' : '#ffffff') : undefined,
          },
        }
  },
  placeholder: (styles) => {
    return {
      ...styles,
      color: '#132530',
      lineHeight: 1.2,
    }
  },
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: '#184460',
      pointerEvents: 'none',
    }
  },
})

type SelectInstance = ReactSelectInstance<SelectItem>

export const InputSelect = forwardRef<SelectInstance, InputSelectProps>(
  (
    {
      className,
      hasDisabled = false,
      loading,
      hasError = false,
      menuPlacement = 'auto',
      isSearchable = false,
      prefixIcon,
      size = 'large',
      isFilter = false,
      maxMenuHeight = 210,
      position = 'absolute',
      placeholder,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation()

    const selectStyle = cn(st['select'], className, {
      [st.disabled]: hasDisabled,
      [st.prefix]: isSearchable,
      [st[`size-${size}`]]: size,
    })

    return (
      <Select
        {...rest}
        maxMenuHeight={maxMenuHeight}
        classNamePrefix={st['select']}
        components={{
          ...components,
          Control: ({ children, ...controlProps }: ControlPropsType) => (
            <ControlItem {...controlProps}>
              <>
                {orNull(
                  isSearchable && prefixIcon,
                  <div className={st['prefix-control']}>
                    <Icon name={prefixIcon} color='blue-gray-7' />
                  </div>,
                )}
                {children}
              </>
            </ControlItem>
          ),
          SingleValue: SelectItemValue,
          MultiValue: SelectItemMultiValue,
          Option: SelectItemOption,
          DropdownIndicator: (indicatorProps: SelectIndicatorProps) => (
            <SelectIndicator loading={loading} {...indicatorProps} />
          ),
          NoOptionsMessage: (props: NoticeProps) => {
            const NoOptionsMessage = components.NoOptionsMessage as React.ComponentType<NoticeProps>

            return <NoOptionsMessage {...props}>{t('fields:default_placeholders.no_options')}</NoOptionsMessage>
          },
        }}
        placeholder={placeholder || t(messages.select.placeholder)}
        menuPlacement={menuPlacement}
        className={selectStyle}
        isDisabled={hasDisabled || loading}
        isSearchable={isSearchable}
        styles={colorStyles(hasError, isFilter, position)}
        ref={ref}
        instanceId={rest.name}
      />
    )
  },
)

InputSelect.displayName = 'InputSelect'
