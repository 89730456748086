import { StaticDataType } from 'src/models'

export const staticDataKeys = {
  all: ['static-data'],
  list: (params: { types: StaticDataType[] }) => [...staticDataKeys.all, 'list', params],
  zones: () => [...staticDataKeys.all, 'zones'],
  sectors: (zoneId?: string) => [...staticDataKeys.all, 'sectors', zoneId],
  customerLabels: (businessTypeId?: string, marketTypeId?: string) => [
    ...staticDataKeys.all,
    'customer-labels',
    businessTypeId,
    marketTypeId,
  ],
}
