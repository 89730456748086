import { trim } from 'ramda'
import { ReactElement } from 'react'

import { isNilOrEmpty } from 'src/utils'

export const WrappedNodeDefault = (
  value?: string | number | null | Date | ReactElement,
): ReactElement | string | null => {
  if (typeof value === 'number') {
    return value.toString() || '-'
  }

  if (value instanceof Date) {
    return JSON.stringify(value || '-')
  }

  if (typeof value === 'string') {
    if (isNilOrEmpty(trim(value))) {
      return '-'
    }

    return value
  }

  return value || '-'
}
