import { HistoryReturnsItem, SkeletonList } from 'src/components/common'
import { Empty, InfiniteScroll } from 'src/components/ui'

import { useReturnsListFetch } from 'src/bus/returns'

import { getInfiniteListLength, getIsShowDate } from 'src/utils'

export const HistoryReturnsTabContent = () => {
  const { returnsList, loadingReturnsListFetch, onFetchMoreReturnsList, returnsListHasMore } = useReturnsListFetch()

  const renderItems = () =>
    returnsList.pages.map((page) => {
      return page.items.map((item, key) => {
        return <HistoryReturnsItem key={item.id} index={key} showDate={getIsShowDate(key, returnsList)} item={item} />
      })
    })

  return (
    <div className='flex h-full flex-col py-2' data-test-id='customers-returns'>
      <InfiniteScroll
        hasMore={returnsListHasMore}
        onFetchMore={onFetchMoreReturnsList}
        dataLength={getInfiniteListLength(returnsList.pages)}
        scrollableTarget='returns'
        loadingFetch={loadingReturnsListFetch}
        renderItems={renderItems}
        loader={<SkeletonList type='history' />}
        empty={<Empty title='common:empty.no_activity' description='common:empty.returns_list' />}
      />
    </div>
  )
}
