import { QueryClient } from '@tanstack/react-query'
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import Router from 'next/router'
import QS from 'qs'

import { book } from 'src/init/book'

import { toastEnhanced } from 'src/components/common'

import { HttpStatusCode, HttpStatusMessage } from 'src/models'

import {
  CLIENT_API_INITIAL_ERR,
  ENCODE_OPTIONS,
  getAccessToken,
  getFormatErrorValidation,
  HttpError,
  removeFromStorage,
} from 'src/utils'

import { API_CLIENT_URL } from 'src/api/config'

interface NetworkErrorDetails {
  type: 'network' | 'server' | 'unknown' | 'proxy' | 'captcha' | 'blocked' | 'redirect'
  title: string
  message: string
  originalError?: Error
}

interface ServerErrorResponse {
  error?: {
    type?: string
    message?: string
    errors?: Record<string, any>
  }
}

interface ExtendedAxiosError extends AxiosError {
  response?: AxiosResponse & {
    data?: ServerErrorResponse
  }
}

function fetchWithTimeout(url: string, options: RequestInit = {}, timeout = 5000): Promise<Response> {
  return new Promise((resolve, reject) => {
    const controller = new AbortController()
    const { signal } = controller

    const timeoutId = setTimeout(() => {
      controller.abort()
      reject(new Error('Request timeout'))
    }, timeout)

    fetch(url, {
      ...options,
      signal,
    })
      .then((response) => {
        clearTimeout(timeoutId)
        resolve(response)
      })
      .catch((error) => {
        clearTimeout(timeoutId)
        reject(error)
      })
  })
}

export const defaultOptions: AxiosRequestConfig = {
  baseURL: API_CLIENT_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 25000,
  paramsSerializer: (params: AxiosRequestConfig) => QS.stringify(params, ENCODE_OPTIONS),
  maxRedirects: 5,
}

export const httpAxiosProvider = axios.create(defaultOptions)

// Enhanced network error detection function
function detectNetworkErrorCause(error: AxiosError): Promise<NetworkErrorDetails> {
  if (navigator.onLine === false) {
    return Promise.resolve({
      type: 'network',
      title: 'Connection Error',
      message: 'No internet connection. Please check your network settings.',
    })
  }

  // Check for CORS error
  if (error.config && error.config.url) {
    try {
      const url = new URL(error.config.baseURL + error.config.url)
      if (url.origin !== window.location.origin) {
        return Promise.resolve({
          type: 'network',
          title: 'CORS Error',
          message: 'Cross-origin request blocked. Check server configuration.',
        })
      }
    } catch (urlError) {
      // URL parsing error
      return Promise.resolve({
        type: 'network',
        title: 'Network Configuration Error',
        message: 'Unable to parse request URL.',
      })
    }
  }

  // Check for VPN or firewall issues
  return new Promise((resolve) => {
    fetchWithTimeout('https://www.google.com', { mode: 'no-cors' })
      .then(() =>
        resolve({
          type: 'network',
          title: 'Request Blocked',
          message: 'Request blocked by VPN, firewall, or network restrictions.',
        }),
      )
      .catch(() =>
        resolve({
          type: 'network',
          title: 'Network Inaccessible',
          message: 'Unable to establish network connection. Check your internet and network settings.',
        }),
      )
  })
}

function detectProxyErrorType(responseData: string): NetworkErrorDetails | null {
  if (typeof responseData !== 'string') {
    return null
  }

  // Check for Cloudflare captcha or challenge
  if (
    responseData.includes('challenge-form') ||
    responseData.includes('cf-challenge') ||
    responseData.includes('_cf_chl_') ||
    responseData.includes('cf_captcha_kind')
  ) {
    return {
      type: 'captcha',
      title: 'Security Check',
      message: 'Cloudflare security check detected. You may need to solve a captcha or pass a challenge.',
    }
  }

  // Check for IP blocking
  if (
    responseData.includes('Access denied') ||
    responseData.includes('IP address has been blocked') ||
    responseData.includes('has been blocked') ||
    responseData.includes('403 Forbidden')
  ) {
    return {
      type: 'blocked',
      title: 'Access Blocked',
      message: 'Your IP address has been blocked by the server or DDoS protection.',
    }
  }

  // Check for rate limiting
  if (
    responseData.includes('rate limit') ||
    responseData.includes('too many requests') ||
    responseData.includes('429 Too Many Requests')
  ) {
    return {
      type: 'proxy',
      title: 'Too Many Requests',
      message: 'Request limit exceeded. Please try again later.',
    }
  }

  // General proxy error
  if (
    responseData.includes('<html') &&
    (responseData.includes('error') ||
      responseData.includes('Error') ||
      responseData.includes('nginx') ||
      responseData.includes('proxy_pass'))
  ) {
    return {
      type: 'proxy',
      title: 'Proxy Server Error',
      message: 'An error occurred while processing the request through the proxy server.',
    }
  }

  return null
}

const isHtmlResponse = (data: any): boolean => {
  return typeof data === 'string' && data.trim().startsWith('<') && data.includes('<html')
}

httpAxiosProvider.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken()

    if (config?.headers && accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  (error) => Promise.reject(error),
)

httpAxiosProvider.interceptors.response.use(
  (response) => response,
  async (error: ExtendedAxiosError) => {
    const queryClient = new QueryClient()

    if (error.response) {
      const response = error?.response

      console.log('Response error', response)

      // Check if the response contains HTML instead of JSON
      if (response?.data && isHtmlResponse(response.data)) {
        const proxyError = detectProxyErrorType(response.data as string)

        if (proxyError) {
          console.info('Proxy error detected:', proxyError)

          toastEnhanced(
            {
              title: proxyError.title,
              body: proxyError.message,
            },
            { type: 'error' },
          )

          return Promise.reject({
            status: response.status,
            data: {
              error: {
                type: proxyError.type,
                message: proxyError.message,
              },
            },
          })
        }
      }

      // Handle redirect errors (300-399)
      if (response?.status && response.status >= 300 && response.status < 400) {
        console.log('Redirect error', response)

        toastEnhanced(
          {
            title: 'Redirect Error',
            body: `A redirect error occurred (${response.status}). Please try refreshing the page.`,
          },
          { type: 'error' },
        )

        return Promise.reject({
          status: response.status,
          data: {
            error: {
              type: 'redirect',
              message: `Redirect Error: ${response.status}`,
            },
          },
        })
      }

      // Handle 401 unauthorized status
      if (
        (response?.status === HttpStatusCode.badRequest &&
          response?.data?.error?.type &&
          response?.data?.error?.message === HttpStatusMessage.unauthenticated) ||
        response?.status === HttpStatusCode.auth
      ) {
        queryClient.clear()
        removeFromStorage()
        await Router.replace(book.signin)

        toastEnhanced(
          {
            title:
              typeof response.data === 'object' && response.data?.error?.type
                ? response.data.error.type
                : 'Authorization Required',
            body:
              typeof response.data === 'object' && response.data?.error?.message
                ? response.data.error.message
                : 'Your session has expired. Please log in again.',
          },
          { type: 'error' },
        )

        return Promise.reject(response)
      }

      // Handle 404 status for GET methods
      if (response?.status === HttpStatusCode.notFound && response?.config.method === 'get') {
        await Router.replace(book.notFound)
        return Promise.reject(response)
      }

      // Handle errors with valid error structure (400-500 range)
      if (
        response?.status >= HttpStatusCode.badRequest &&
        response?.data?.error?.type &&
        response?.data?.error?.message
      ) {
        if (response.status !== HttpStatusCode.unprocessableEntity) {
          console.info('Error details:', response)
          toastEnhanced(
            {
              title: response.data.error.type,
              body: response.data.error.message,
            },
            { type: 'error' },
          )
        }

        const errLocalized = getFormatErrorValidation(response.data.error)
        throw new HttpError(response.status, errLocalized)
      }

      // Handle 503 Service Unavailable
      if (response?.status === HttpStatusCode.serviceUnavailable) {
        toastEnhanced(
          {
            title:
              typeof response.data === 'object' && response.data?.error?.type
                ? response.data.error.type
                : 'Service Unavailable',
            body:
              typeof response.data === 'object' && response.data?.error?.message
                ? response.data.error.message
                : 'The service is temporarily unavailable. Please try again later.',
          },
          { type: 'error' },
        )

        return Promise.reject(response)
      }

      // Handle other HTTP errors (any uncaught error with status code)
      if (response?.status) {
        const statusGroup = Math.floor(response.status / 100)
        let errorTitle = 'Error'
        let errorMessage = 'An unknown error occurred.'

        switch (statusGroup) {
          case 4:
            errorTitle = 'Request Error'
            errorMessage = `The server could not process the request (${response.status}).`
            break
          case 5:
            errorTitle = 'Server Error'
            errorMessage = `An error occurred on the server (${response.status}).`
            break
          default:
            errorTitle = `HTTP Error ${response.status}`
            errorMessage = 'An unexpected error occurred while processing the request.'
        }

        toastEnhanced({ title: errorTitle, body: errorMessage }, { type: 'error' })

        return Promise.reject({
          status: response.status,
          data: { error: { type: 'http_error', message: errorMessage } },
        })
      }

      // Handle server errors (500 range) - default fallback
      toastEnhanced(
        {
          title: 'Server Error',
          body: 'An unexpected server error occurred.',
        },
        { type: 'error' },
      )

      return Promise.reject({
        status: CLIENT_API_INITIAL_ERR.status,
        data: CLIENT_API_INITIAL_ERR.error,
      })
    } else if (error.request) {
      console.log('Request error', error)

      if (error.code === 'ERR_NETWORK') {
        const networkError = await detectNetworkErrorCause(error)

        toastEnhanced(
          {
            title: networkError.title,
            body: networkError.message,
          },
          { type: 'error' },
        )

        return Promise.reject({
          status: error.request.status || 0,
          data: {
            error: {
              type: networkError.type,
              message: networkError.message,
            },
          },
        })
      }

      toastEnhanced(
        {
          title: 'Network Request Error',
          body: 'Unable to complete the network request.',
        },
        { type: 'error' },
      )

      return Promise.reject({
        status: error.request.status || 0,
        data: { error: { type: 'error', message: error.message } },
      })
    } else {
      toastEnhanced(
        {
          title: 'Unexpected Error',
          body: 'An unexpected error occurred.',
        },
        { type: 'error' },
      )

      return Promise.reject()
    }
  },
)
