import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { Dialog, DialogViewsSwitcher, SkeletonList, VisitsListHistoryItem } from 'src/components/common'
import { Empty, InfiniteScroll, Typography } from 'src/components/ui'

import { useVisitsHistoryFetch } from 'src/bus/visitsHistory'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, getInfiniteListLength, rowGutters } from 'src/utils'

export const VisitsHistoryList = () => {
  const { t } = useTranslation()
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { visitsHistory, visitsHistoryHasMore, onFetchMoreVisitsHistory, loadingVisitsHistoryFetch } =
    useVisitsHistoryFetch()

  const [dialogParams, setDialogParams] = useState<{
    id?: number
    title?: string
  }>()

  const onGetDialogParams = ({ id, title }: { id?: number; title?: string }) => setDialogParams({ id, title })

  const renderItems = () =>
    visitsHistory.pages.map((page) => {
      return page.items.map((item) => (
        <VisitsListHistoryItem key={item.id} item={item} onGetDialogParams={onGetDialogParams} />
      ))
    })

  return (
    <div className='flex h-full flex-col py-2' data-test-id='visits-history'>
      {[GET_ENUMS.dialog.cancelVisit, GET_ENUMS.dialog.replanVisit].map((key) => {
        return (
          <Dialog key={key} open={onPageDialogs[key]?.visible} onOpenChange={(open) => setOnPageDialog(key, open)}>
            <DialogViewsSwitcher name={key} params={dialogParams} />
          </Dialog>
        )
      })}
      <div className='mb-2 px-4'>
        <Row align='middle' gutter={rowGutters}>
          <Col span={12}>
            <Typography variant='secondary' color='gray-7'>
              {t('customers:customer_name')}
            </Typography>
          </Col>
          <Col span={5}>
            <Typography variant='secondary' color='gray-7'>
              {t('customers:status')}
            </Typography>
          </Col>
          <Col span={7}>
            <Typography variant='secondary' color='gray-7' className='text-center'>
              {t('customers:status_and_date')}
            </Typography>
          </Col>
        </Row>
      </div>
      <InfiniteScroll
        hasMore={visitsHistoryHasMore}
        onFetchMore={onFetchMoreVisitsHistory}
        dataLength={getInfiniteListLength(visitsHistory.pages)}
        loadingFetch={loadingVisitsHistoryFetch}
        loader={<SkeletonList type='visits-history' />}
        renderItems={renderItems}
        scrollableTarget='visits-history'
        empty={<Empty title='common:hey' description='common:empty.no_visits' />}
      />
    </div>
  )
}
