import { useCallback, useState } from 'react'

import { CreateCustomerForm } from 'src/components/common'

import { useCustomersCreate, useCustomersStaticDataFetch } from 'src/bus/customers'
import {
  useStaticDataCustomerLabelsFetch,
  useStaticDataSectorsFetch,
  useStaticDataZonesFetch,
} from 'src/bus/staticData'

export const CustomersCreate = () => {
  const { onCreateCustomer, loadingCustomersCreate } = useCustomersCreate()

  const [selectedZoneId, setSelectedZoneId] = useState<string>()
  const [selectedBusinessTypeId, setSelectedBusinessTypeId] = useState<string>()

  const { customersFilters, loadingCustomerStaticDataFetch } = useCustomersStaticDataFetch()
  const { staticDataCustomerLabels, loadingStaticDataCustomerLabelsFetch } =
    useStaticDataCustomerLabelsFetch(selectedBusinessTypeId)

  const onSelectZoneId = useCallback((id: string) => setSelectedZoneId(id), [])
  const onSelectBusinessTypeId = useCallback((id: string) => setSelectedBusinessTypeId(id), [])

  const { staticDataZones, loadingStaticDataZonesFetch } = useStaticDataZonesFetch()
  const { staticDataSectors, loadingStaticDataSectorsFetch } = useStaticDataSectorsFetch(selectedZoneId)

  return (
    <div className='flex h-full flex-col py-2' data-test-id='customers-create'>
      <CreateCustomerForm
        onSubmit={onCreateCustomer}
        filters={customersFilters}
        onSelectZoneId={onSelectZoneId}
        onSelectBusinessTypeId={onSelectBusinessTypeId}
        zonesOptions={staticDataZones.zones}
        sectorsOptions={staticDataSectors.sectors}
        customerLabelsOptions={staticDataCustomerLabels.customerLabels}
        loadingCustomerLabelsFetch={loadingStaticDataCustomerLabelsFetch}
        loadingFetchStaticData={loadingCustomerStaticDataFetch}
        loadingCreate={loadingCustomersCreate}
        loadingZonesFetch={loadingStaticDataZonesFetch}
        loadingSectorsFetch={loadingStaticDataSectorsFetch}
      />
    </div>
  )
}
