import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { FilterStaticType, FormSubmitParams } from 'src/models'

import { settingsKeys, useSettingsUpdateMutation } from 'src/bus/settings'
import { staticDataKeys } from 'src/bus/staticData'

import { useToast } from 'src/hooks'

import { useDrawer } from 'src/contexts'

import { GET_ENUMS, showCatchErrors } from 'src/utils'

type NavigationUpdateCbType = (data: FormSubmitParams<FilterStaticType>) => Promise<void>
type UseNavigationUpdateType = {
  loadingNavigationUpdate: boolean
  onUpdateNavigation: NavigationUpdateCbType
}

export const useNavigationUpdate = (): UseNavigationUpdateType => {
  const queryClient = useQueryClient()
  const showToast = useToast()
  const { setOnPageDrawer } = useDrawer()
  const { onUpdateSettingsMutation } = useSettingsUpdateMutation()

  const onUpdateNavigation = useCallback<NavigationUpdateCbType>(
    async (data) => {
      try {
        const navigator = data.values.navigator?.[0]

        const values = { data: { navigator } }

        await onUpdateSettingsMutation.mutateAsync(values)
        setOnPageDrawer(GET_ENUMS.drawer.navigation, false)
        await queryClient.invalidateQueries({ queryKey: settingsKeys.settings() })
        await queryClient.refetchQueries({ queryKey: staticDataKeys.all })
      } catch (error) {
        showCatchErrors(error, showToast)
      }
    },
    [onUpdateSettingsMutation, showToast, setOnPageDrawer, queryClient],
  )

  return {
    loadingNavigationUpdate: onUpdateSettingsMutation.isPending,
    onUpdateNavigation,
  }
}
