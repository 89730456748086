import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { FilterStaticType, FormSubmitParams } from 'src/models'

import { useSettingsUpdateMutation } from 'src/bus/settings'

import { useToast } from 'src/hooks'

import { useDrawer } from 'src/contexts'

import { GET_ENUMS, showCatchErrors } from 'src/utils'

type LanguageUpdateCbType = (data: FormSubmitParams<FilterStaticType>) => Promise<void>
type UseLanguageUpdateType = {
  loadingLanguageUpdate: boolean
  onUpdateLanguage: LanguageUpdateCbType
}

export const useLanguageUpdate = (): UseLanguageUpdateType => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const showToast = useToast()
  const { setOnPageDrawer } = useDrawer()
  const { onUpdateSettingsMutation } = useSettingsUpdateMutation()

  const onUpdateLanguage = useCallback<LanguageUpdateCbType>(
    async (data) => {
      try {
        const language = data.values.languages?.[0]

        const values = { data: { language } }

        await onUpdateSettingsMutation.mutateAsync(values)
        setOnPageDrawer(GET_ENUMS.drawer.language, false)
        dayjs.locale(language?.value)
        queryClient.clear()
        await router.replace(router.asPath, undefined, { locale: language?.value })
      } catch (error) {
        showCatchErrors(error, showToast)
      }
    },
    [onUpdateSettingsMutation, showToast, setOnPageDrawer, router, queryClient],
  )

  return {
    loadingLanguageUpdate: onUpdateSettingsMutation.isPending,
    onUpdateLanguage,
  }
}
