import { useEffect } from 'react'

import { ServerErrorFieldFormatType, SetErrorFnType } from 'src/models'

import { fillServerErrorsToForm } from 'src/utils'

export const useFillServerErrorsToForm = <T = ServerErrorFieldFormatType>(
  setError: SetErrorFnType<T>,
  error?: ServerErrorFieldFormatType,
) => {
  useEffect(() => {
    if (typeof error === 'object') {
      fillServerErrorsToForm(error, setError)
    }
  }, [error, setError])
}
