import { Fragment, PropsWithChildren } from 'react'

import {
  DialogEditNote,
  DialogCancelVisit,
  DialogReplanVisit,
  DialogNoPoints,
  DynamicImport,
} from 'src/components/common'

import { ParcelsArticleType, ParcelType, TGoogleMap } from 'src/models'

import { GET_ENUMS } from 'src/utils'

type DialogViewsSwitcherProps = PropsWithChildren<{
  name?: string
  params?: {
    id?: number
    visitId?: number
    title?: string
    description?: string
    parcelsArticle?: ParcelsArticleType | null
    parcelItem?: ParcelType | null
    google?: TGoogleMap
    onSubmitForm?: () => Promise<void>
    loadingSubmit?: boolean
    onOpenOtherPopup?: (key: string) => void
  }
}>

export const DialogViewsSwitcher = ({ name = '', params }: DialogViewsSwitcherProps) => {
  const dialogs: Record<string, any> = {
    [GET_ENUMS.dialog.stopTour]: () => {
      const DialogStopTourDynamic = DynamicImport(import('../common/DialogViews/DialogStopTour'), 'DialogStopTour')
      return <DialogStopTourDynamic />
    },
    [GET_ENUMS.dialog.createCoupon]: () => {
      const DialogCreateCouponDynamic = DynamicImport(
        import('../common/DialogViews/DialogCreateCoupon'),
        'DialogCreateCoupon',
      )
      return <DialogCreateCouponDynamic />
    },
    [GET_ENUMS.dialog.cancelVisit]: () => {
      // const DialogCancelVisitDynamic = DynamicImport(
      //   import('../common/DialogViews/DialogCancelVisit'),
      //   'DialogCancelVisit',
      // )
      return <DialogCancelVisit {...params} />
    },
    [GET_ENUMS.dialog.replanVisit]: () => {
      // const DialogReplanVisitDynamic = DynamicImport(
      //   import('../common/DialogViews/DialogReplanVisit'),
      //   'DialogReplanVisit',
      // )
      return <DialogReplanVisit {...params} />
    },
    [GET_ENUMS.dialog.customerCredentials]: () => {
      const DialogCustomerCredentialsDynamic = DynamicImport(
        import('../common/DialogViews/DialogCustomerCredentials'),
        'DialogCustomerCredentials',
      )
      return <DialogCustomerCredentialsDynamic />
    },
    [GET_ENUMS.dialog.customerIssue]: () => {
      const DialogCustomerIssueDynamic = DynamicImport(
        import('../common/DialogViews/DialogCustomerIssue'),
        'DialogCustomerIssue',
      )
      return <DialogCustomerIssueDynamic />
    },
    [GET_ENUMS.dialog.editNote]: () => {
      // const DialogEditNoteDynamic = DynamicImport(import('../common/DialogViews/DialogEditNote'), 'DialogEditNote')
      return <DialogEditNote />
    },
    [GET_ENUMS.dialog.noVisits]: () => {
      const DialogNoVisitsDynamic = DynamicImport(import('../common/DialogViews/DialogNoVisits'), 'DialogNoVisits')
      return <DialogNoVisitsDynamic />
    },
    [GET_ENUMS.dialog.noPoints]: () => {
      // const DialogNoPointsDynamic = DynamicImport(import('../common/DialogViews/DialogNoPoints'), 'DialogNoPoints')
      return <DialogNoPoints />
    },
    [GET_ENUMS.dialog.vatInformation]: () => {
      const DialogVatInformationDynamic = DynamicImport(
        import('../common/DialogViews/DialogVatInformation'),
        'DialogVatInformation',
      )
      return <DialogVatInformationDynamic {...params} />
    },
    [GET_ENUMS.dialog.cancelVisitReason]: () => {
      const DialogCancelVisitReasonDynamic = DynamicImport(
        import('../common/DialogViews/DialogCancelVisitReason'),
        'DialogCancelVisitReason',
      )
      return <DialogCancelVisitReasonDynamic {...params} />
    },
    [GET_ENUMS.dialog.info]: () => {
      const DialogInfoDynamic = DynamicImport(import('../common/DialogViews/DialogInfo'), 'DialogInfo')
      return <DialogInfoDynamic {...params} />
    },
    [GET_ENUMS.dialog.returnsInfoMarkers]: () => {
      const DialogReturnsInfoMarkersDynamic = DynamicImport(
        import('../common/DialogViews/DialogReturnsInfoMarkers/DialogReturnsInfoMarkers'),
        'DialogReturnsInfoMarkers',
      )
      return <DialogReturnsInfoMarkersDynamic />
    },
    [GET_ENUMS.dialog.deleteArticle]: () => {
      const DialogDeleteArticleDynamic = DynamicImport(
        import('../common/DialogViews/DialogDeleteArticle'),
        'DialogDeleteArticle',
      )
      return <DialogDeleteArticleDynamic {...params} />
    },
    [GET_ENUMS.dialog.moveToArticle]: () => {
      const DialogMoveToArticleDynamic = DynamicImport(
        import('../common/DialogViews/DialogMoveToArticle'),
        'DialogMoveToArticle',
      )
      return <DialogMoveToArticleDynamic {...params} />
    },
    [GET_ENUMS.dialog.moveOutArticle]: () => {
      const DialogMoveOutArticleDynamic = DynamicImport(
        import('../common/DialogViews/DialogMoveOutArticle'),
        'DialogMoveOutArticle',
      )
      return <DialogMoveOutArticleDynamic {...params} />
    },
    [GET_ENUMS.dialog.recoverArticle]: () => {
      const DialogRecoverArticleDynamic = DynamicImport(
        import('../common/DialogViews/DialogRecoverArticle'),
        'DialogRecoverArticle',
      )
      return <DialogRecoverArticleDynamic {...params} />
    },
    [GET_ENUMS.dialog.replaceArticle]: () => {
      const DialogReplaceArticleDynamic = DynamicImport(
        import('../common/DialogViews/DialogReplaceArticle'),
        'DialogReplaceArticle',
      )
      return <DialogReplaceArticleDynamic {...params} />
    },
    [GET_ENUMS.dialog.showQRCode]: () => {
      const DialogShowQRCodeDynamic = DynamicImport(
        import('../common/DialogViews/DialogShowQRCode'),
        'DialogShowQRCode',
      )
      return <DialogShowQRCodeDynamic {...params} />
    },
    [GET_ENUMS.dialog.trackNumberNL]: () => {
      const DialogTrackNumberNLDynamic = DynamicImport(
        import('../common/DialogViews/DialogTrackNumberNL'),
        'DialogTrackNumberNL',
      )
      return <DialogTrackNumberNLDynamic {...params} />
    },
    [GET_ENUMS.dialog.farAwayReport]: () => {
      const DialogFarAwayReportDynamic = DynamicImport(
        import('../common/DialogViews/DialogFarAwayReport'),
        'DialogFarAwayReport',
      )
      return <DialogFarAwayReportDynamic {...params} />
    },
    [GET_ENUMS.dialog.customerCard]: () => {
      const DialogCustomerCardDynamic = DynamicImport(
        import('../common/DialogViews/DialogCustomerCard'),
        'DialogCustomerCard',
      )
      return <DialogCustomerCardDynamic {...params} />
    },
    default: () => <Fragment />,
  }

  return dialogs?.[name]?.() || dialogs['default']()
}
