import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { DocumentCallback } from 'react-pdf/src/shared/types.ts'

import { Loader } from 'src/components/ui'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@5.0.375/build/pdf.worker.min.mjs`

type PDFViewerProps = {
  value?: string
}

export const PDFViewer = ({ value }: PDFViewerProps) => {
  const [numPages, setNumPages] = useState<number>()

  const onDocumentLoadSuccess = ({ numPages }: DocumentCallback) => {
    setNumPages(numPages)
  }

  return (
    <div className='pdf-container'>
      <Document
        file={value}
        loading={<Loader type='relative' />}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => console.error('Error loading PDF:', error)}
      >
        {Array.from(new Array(numPages), (_, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={true} renderAnnotationLayer={true} />
        ))}
      </Document>
    </div>
  )
}
