import { DndContext, closestCenter, useSensor, useSensors, MouseSensor, TouchSensor } from '@dnd-kit/core'
import type { DragEndEvent } from '@dnd-kit/core/dist/types'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { Dialog, VisitListItem, SkeletonList, DialogViewsSwitcher } from 'src/components/common'
import { Empty, Typography } from 'src/components/ui'

import { useVisitsFetch, useVisitsOrderUpdate } from 'src/bus/visits'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, getHasData, getIds, ternary } from 'src/utils'

export const VisitList = () => {
  const { t } = useTranslation()
  const { visits, loadingVisitsFetch } = useVisitsFetch()
  const { onUpdateVisitsOrder } = useVisitsOrderUpdate()
  const { onPageDialogs, setOnPageDialog } = useDialog()

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    }),
  )

  const [dialogParams, setDialogParams] = useState<{
    id?: number
    title?: string
  }>()

  const onDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event
    if (active.id !== over?.id) {
      const oldIndex = visits.items.findIndex((item) => item.id === active.id)
      const newIndex = visits.items.findIndex((item) => item.id === over?.id)

      const updated = arrayMove(visits.items, oldIndex, newIndex)

      await onUpdateVisitsOrder(getIds(updated))
    }
  }

  const onGetDialogParams = ({ id, title }: { id?: number; title?: string }) => setDialogParams({ id, title })

  return (
    <>
      {[GET_ENUMS.dialog.cancelVisit, GET_ENUMS.dialog.replanVisit].map((key) => {
        return (
          <Dialog key={key} open={onPageDialogs[key]?.visible} onOpenChange={(open) => setOnPageDialog(key, open)}>
            <DialogViewsSwitcher name={key} params={dialogParams} />
          </Dialog>
        )
      })}
      <div className='px-6 py-2'>
        <div className='flex items-center gap-2'>
          <div className='grid basis-1/12'>
            <Typography variant='secondary' color='gray-7'>
              {t('visits:visits_list.number')}
            </Typography>
          </div>
          <div className='grid basis-6/12'>
            <Typography variant='secondary' color='gray-7'>
              {t('visits:visits_list.customer_name')}
            </Typography>
          </div>
          <div className='grid basis-2.5/12 text-center'>
            <Typography variant='secondary' color='gray-7'>
              {t('visits:visits_list.status')}
            </Typography>
          </div>
          <div className='grid basis-2.5/12 text-center'>
            <Typography variant='secondary' color='gray-7'>
              {t('visits:visits_list.sector')}
            </Typography>
          </div>
        </div>
      </div>
      {ternary(
        loadingVisitsFetch,
        <SkeletonList type='customers' />,
        ternary(
          getHasData(visits.items),
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={onDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
          >
            <SortableContext items={visits.items} strategy={verticalListSortingStrategy}>
              <div className='flex h-full flex-col overflow-y-auto px-2'>
                {visits.items.map((item, index) => {
                  return <VisitListItem key={item.id} item={item} index={index} onGetDialogParams={onGetDialogParams} />
                })}
              </div>
            </SortableContext>
          </DndContext>,
          <Empty title='common:hey' description='common:empty.no_visits' />,
        ),
      )}
    </>
  )
}
