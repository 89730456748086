import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { GET_ENUMS } from 'utils/constants'
import { formatNotificationMessage, isHttpErrorType } from 'utils/helpers'

import { couponsKeys, useCouponsCreateMutation } from 'src/bus/coupons'

import { useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

type UseCouponCreateType = {
  loadingCouponCreate: boolean
  onCouponCreate: (type?: string) => void
}

export const useCouponsCreate = (): UseCouponCreateType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const router = useRouter()
  const showToast = useToast()
  const { onCreateCouponMutation } = useCouponsCreateMutation()
  const { setOnPageDialog } = useDialog()

  const visitId = Number(router.query.id)

  const onCouponCreate = useCallback(
    async (type?: string) => {
      try {
        await onCreateCouponMutation.mutateAsync({ data: { visitId, ...(type && { type }) } })
        await queryClient.refetchQueries({ queryKey: couponsKeys.coupons(visitId) })
        showToast.success({ title: t('notifications:coupon_create') })
      } catch (error) {
        if (isHttpErrorType(error)) {
          showToast.error(formatNotificationMessage(error.errors))
          setOnPageDialog(GET_ENUMS.dialog.createCoupon, false)
          await queryClient.resetQueries({ queryKey: couponsKeys.coupons(visitId) })
        }
      }
    },
    [visitId, onCreateCouponMutation, showToast, t, queryClient, setOnPageDialog],
  )

  return {
    loadingCouponCreate: onCreateCouponMutation.isPending,
    onCouponCreate,
  }
}
