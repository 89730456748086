import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { FormSubmitParams, FormTrackNumberType, HttpErrorType, ParcelsInfiniteType, ParcelType } from 'src/models'

import { useParcelsShipmentMutation, parcelsKeys } from 'src/bus/parcels'

import { useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { fillServerErrorsToForm, GET_ENUMS, getInfiniteList, getParamsFromQueryStr } from 'src/utils'

type SendRequestCbType = (id?: number, data?: FormSubmitParams<FormTrackNumberType>) => Promise<void>

type UseParcelsShipmentRequestType = {
  loadingParcelsShipmentRequest: boolean
  onShipmentParcels: SendRequestCbType
}

export const useParcelsShipmentRequest = (): UseParcelsShipmentRequestType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const router = useRouter()
  const { onShipmentParcelsMutation } = useParcelsShipmentMutation()
  const showToast = useToast()
  const { setOnPageDialog } = useDialog()

  const initialQuery = getParamsFromQueryStr(router.query)

  const onShipmentParcels = useCallback<SendRequestCbType>(
    async (id, data) => {
      try {
        await onShipmentParcelsMutation.mutateAsync({ params: { id: String(id) }, data: data?.values })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.lists() })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.detail(Number(id)) })
        showToast.success({ title: t('notifications:parcels_shipment_sent') })
        setOnPageDialog(GET_ENUMS.dialog.trackNumberNL, false)

        const parcelsList: ParcelsInfiniteType | undefined = queryClient.getQueryData(parcelsKeys.list(initialQuery))
        const parcels = getInfiniteList(parcelsList?.pages || []) as unknown as ParcelType[]
        const parcel = parcels.find((el) => el?.id === id)

        if (parcel?.isAvailableQrCode) {
          setOnPageDialog(GET_ENUMS.alertDialog.sendRequest, true)
        }
      } catch (error) {
        const errs = (error as HttpErrorType).errors || {}
        const entries = Object.entries(errs)

        if (data?.acts?.setError) {
          fillServerErrorsToForm(errs, data?.acts?.setError)
        } else if (entries.length > 0) {
          const [[title, body]] = entries
          showToast.error({ title, body })
        }
      }
    },
    [t, onShipmentParcelsMutation, showToast, initialQuery, setOnPageDialog, queryClient],
  )

  return {
    onShipmentParcels,
    loadingParcelsShipmentRequest: onShipmentParcelsMutation.isPending,
  }
}
