import { useTranslation } from 'next-i18next'

import {
  HistoryCard,
  HistoryItemRow,
  WrappedNodeDefault,
  HistoryEllipsis,
  HistoryItem,
  CellStatus,
} from 'src/components/common'
import { Typography } from 'src/components/ui'

import { HistoryStatusType } from 'src/models'

import { formatDate, getTranslate, ternary } from 'src/utils'

type HistoryStatusItemProps = {
  item: HistoryStatusType
  showDate: boolean
  index: number
}

export const HistoryStatusItem = ({ item, index, showDate }: HistoryStatusItemProps) => {
  const { t } = useTranslation()

  const getHistoryT = getTranslate('history', t)

  return (
    <HistoryItem date={item.date} index={index} showDate={showDate}>
      <HistoryCard variant='status'>
        <div className='flex items-center gap-2'>
          <Typography className='font-semibold'>{getHistoryT('status')}</Typography>
          <CellStatus value={item.status} />
        </div>
        <HistoryItemRow
          leftSize='basis-3/12'
          label={getHistoryT('date_time')}
          description={WrappedNodeDefault(formatDate(item.date, 'slashTime'))}
        />
        <HistoryItemRow
          label={getHistoryT('reason')}
          description={
            <>
              {ternary(
                item.reason,
                <HistoryEllipsis content={item.reason} rows={2} direction='end' />,
                WrappedNodeDefault(''),
              )}
            </>
          }
        />
      </HistoryCard>
    </HistoryItem>
  )
}
