import NextImage from 'next/image'
import { memo, ReactNode, useEffect, useMemo, useState } from 'react'
import { decodeBase64Image, decodeBase64Pdf, isBase64Pdf, isBase64Png } from 'utils/base64Image'

import { ShowQRCode } from 'src/components/common'
import { DynamicImport } from 'src/components/common/DynamicImport'
import { Typography } from 'src/components/ui'

type Base64CheckerProps = {
  base64String?: string
}

const PDFViewer = DynamicImport(import('../common/PDFViewer'), 'PDFViewer')

export const Base64Checker = memo(({ base64String }: Base64CheckerProps) => {
  const base64StringMemo = useMemo(() => base64String, [base64String])
  const isPngImage = useMemo(() => isBase64Png(base64StringMemo), [base64StringMemo])
  const isPdfImage = useMemo(() => isBase64Pdf(base64StringMemo), [base64StringMemo])

  const [imageUrl, setImageUrl] = useState('')
  const [pdfData, setPdfData] = useState('')

  useEffect(() => {
    if (isPngImage && base64StringMemo) {
      const url = decodeBase64Image(base64StringMemo)

      setImageUrl(url)

      return () => {
        if (url) URL.revokeObjectURL(url)
      }
    }
  }, [isPngImage, base64StringMemo])

  useEffect(() => {
    if (isPdfImage && base64StringMemo) {
      const pdfString = decodeBase64Pdf(base64StringMemo)

      setPdfData(pdfString)
    }
  }, [isPdfImage, base64StringMemo])

  const renderWithShowError = (component: ReactNode, value: string) => {
    if (value) {
      return component
    } else {
      return <Typography variant='secondary'>Failed to decode</Typography>
    }
  }

  return (
    <div>
      {isPngImage ? (
        <div className='relative flex h-[382px] w-full justify-center'>
          {renderWithShowError(
            <NextImage src={imageUrl} alt='Base64 Image' fill style={{ objectFit: 'contain' }} />,
            imageUrl,
          )}
        </div>
      ) : isPdfImage ? (
        <PDFViewer value={pdfData} />
      ) : (
        <ShowQRCode value={base64StringMemo} />
      )}
    </div>
  )
})
