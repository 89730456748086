import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { showCatchErrors } from 'utils/helpers'

import { ArticlesType } from 'src/models'

import { parcelsKeys, useParcelsMoveAllToMutation } from 'src/bus/parcels'

import { useToast } from 'src/hooks'

type UseParcelsMoveAllToType = {
  loadingParcelsMoveAllTo: boolean
  onMoveAllToParcels: () => void
}
export const useParcelsMoveAllTo = (type: ArticlesType): UseParcelsMoveAllToType => {
  const queryClient = useQueryClient()
  const showToast = useToast()
  const { onMoveAllToParcelsMutation } = useParcelsMoveAllToMutation()

  const onMoveAllToParcels = useCallback(async () => {
    try {
      await onMoveAllToParcelsMutation.mutateAsync({ data: { type } })
      await queryClient.invalidateQueries({ queryKey: parcelsKeys.all })
      await queryClient.refetchQueries({ queryKey: parcelsKeys.articles() })
    } catch (error) {
      showCatchErrors(error, showToast)
    }
  }, [showToast, onMoveAllToParcelsMutation, type, queryClient])

  return {
    loadingParcelsMoveAllTo: onMoveAllToParcelsMutation.isPending,
    onMoveAllToParcels,
  }
}
