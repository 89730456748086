import { FormState } from 'react-hook-form'
import { REGEXP_ALPHA_NUMERIC, REGEXP_INTEGER } from 'utils/constants'

import {
  BaseShapeFormType,
  FieldMetaStatus,
  FieldMetaHelpText,
  FieldsMetaType,
  TouchedFields,
  ErrorsFields,
  SetErrorFnType,
  ServerErrorFieldFormatType,
} from 'src/models'

export const getFieldValidate = (key = '', touched: TouchedFields = {}, errors: ErrorsFields = {}): FieldMetaStatus => {
  const isInValid = errors[key]
  const isValid = touched[key] && !errors[key]
  return isInValid ? 'error' : isValid ? 'success' : void 0
}

export const getFieldHelpText = (key = '', errors: ErrorsFields | any = {}): FieldMetaHelpText => {
  if (Array.isArray(errors[key])) {
    return {}
  }

  return {
    ...(key in errors ? { help: errors[key]?.message || '' } : {}),
  }
}

export const getFieldsMetaValidation = (
  shape: BaseShapeFormType,
  formState: FormState<BaseShapeFormType>,
): {
  [key: string]: {
    status: FieldMetaStatus
    helpText: FieldMetaHelpText
  }
} => {
  const { touchedFields, errors } = formState
  return Object.keys(shape).reduce((acc, key) => {
    const status = getFieldValidate(key, touchedFields, errors)
    const helpText = getFieldHelpText(key, errors)
    const fieldMeta = {
      [key]: {
        status,
        helpText,
      },
    }

    return {
      ...acc,
      ...fieldMeta,
    }
  }, {})
}

export const getPartialFieldsMetaValidation = <Shape extends object, Result = FieldsMetaType<Shape>>(
  shape: Record<keyof Shape, Shape[keyof Shape]>,
  touched: TouchedFields = {},
  errors: ErrorsFields = {},
  baseName: Array<string | number> = [],
): Result => {
  return Object.keys(shape).reduce((acc, key) => {
    const status = getFieldValidate(key, touched, errors)
    const helpText = getFieldHelpText(key, errors)

    const fieldMeta = {
      [key]: {
        name: [...baseName, key].join('.'),
        status,
        helpText,
      },
    }

    return {
      ...acc,
      ...fieldMeta,
    }
  }, {} as Result)
}

export const fillServerErrorsToForm = <T = Record<string, string>>(
  errors: ServerErrorFieldFormatType,
  setError: SetErrorFnType<T>,
) => {
  return (
    errors &&
    Object.keys(errors).forEach((key) => {
      setError(key as keyof T, {
        type: 'server',
        message: errors[key],
      })
    })
  )
}

export const getPatternByCountry = (country: string) => {
  return (
    {
      fr: REGEXP_ALPHA_NUMERIC,
      de: REGEXP_ALPHA_NUMERIC,
      nl: REGEXP_ALPHA_NUMERIC,
      at: REGEXP_ALPHA_NUMERIC,
      be: REGEXP_ALPHA_NUMERIC,
      it: REGEXP_INTEGER,
    }[country] || REGEXP_ALPHA_NUMERIC
  )
}
