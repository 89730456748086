import { useTranslation } from 'next-i18next'

import { Dialog, DialogViewsSwitcher } from 'src/components/common'
import { Typography, Button, Separator, Loader } from 'src/components/ui'

import { useCustomersDetailsFetch } from 'src/bus/customers'
import { useKamCustomersFetch } from 'src/bus/kam-customers'

import { useDialog } from 'src/contexts'

import { getCustomerModel, ternary, orNull, GET_ENUMS, MARKET_TYPE_KAM } from 'src/utils'

export const CustomersDetails = ({ visitId }: { visitId?: number }) => {
  const { t } = useTranslation()
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()

  const isKamMarketType = customersDetails?.marketType?.value === MARKET_TYPE_KAM

  const { kamCustomers, loadingKamCustomersFetch } = useKamCustomersFetch(Number(customersDetails?.id), isKamMarketType)

  const customerData = getCustomerModel({
    data: customersDetails,
    kamData: kamCustomers,
    loadingFetch: loadingCustomersDetailsFetch || loadingKamCustomersFetch,
    visitId,
    t,
  })

  return (
    <>
      <Dialog
        open={onPageDialogs[GET_ENUMS.dialog.vatInformation]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.dialog.vatInformation, open)}
      >
        <DialogViewsSwitcher name={GET_ENUMS.dialog.vatInformation} params={{ title: customersDetails?.title }} />
      </Dialog>
      {customerData.customerInfo.map((item) => {
        if (item.hidden) {
          return null
        }

        return (
          <div key={item.id} className='mt-3 bg-gray-1'>
            <Separator className='mb-3' />
            <div className='flex items-center justify-between gap-2'>
              <div className='basis-4.5/12'>
                <Typography variant='secondary' color='gray-7'>
                  {item.title}
                </Typography>
              </div>
              <div className='basis-7.5/12'>{item.component()}</div>
            </div>
          </div>
        )
      })}
      <Separator className='my-3' />
      <Button block variant='ghost' onClick={() => setOnPageDialog(GET_ENUMS.dialog.vatInformation, true)}>
        {t('buttons:legal_information')}
      </Button>
      <Separator className='mb-1 mt-3' />
      <>
        {ternary(
          loadingCustomersDetailsFetch,
          <div className='flex items-center justify-center py-2'>
            <Loader type='relative' />
          </div>,
          orNull(
            isKamMarketType,
            <div className='pb-2'>
              <div className='mt-4'>
                <Typography className='font-semibold'>{t('customers:market_structure')}</Typography>
              </div>
              {customerData.marketInfo.map((item) => {
                return (
                  <div key={item.id} className='mt-3 bg-gray-1'>
                    <div className='flex items-center justify-between gap-2'>
                      <div className='basis-4.5/12'>
                        <Typography variant='secondary' color='gray-7'>
                          {item.title}
                        </Typography>
                      </div>
                      <div className='basis-7.5/12'>{item.component()}</div>
                    </div>
                    <Separator className='mt-3' />
                  </div>
                )
              })}
            </div>,
          ),
        )}
      </>
      <>
        {ternary(
          loadingCustomersDetailsFetch,
          <div className='flex items-center justify-center py-2'>
            <Loader type='relative' />
          </div>,
          orNull(
            customerData.contactPersons.length,
            <div className='pb-2'>
              <div className='mt-3'>
                <Typography className='font-semibold'>{t('customers:contact_persons')}</Typography>
              </div>
              {customerData.contactPersons.map((item) => {
                return (
                  <div key={item.id} className='mt-3'>
                    <div className=' flex items-center justify-between gap-2'>
                      <div className='basis-4.5/12'>
                        <div className='mb-1'>
                          <Typography variant='secondary' color='gray-7'>
                            {item.titleName}
                          </Typography>
                        </div>
                        <div className='mb-1'>{item.phonesTitle()}</div>
                        <Typography variant='secondary' color='gray-7'>
                          {item.positionTitle}
                        </Typography>
                      </div>
                      <div className='basis-7.5/12'>
                        <div className='mb-1'>{item.componentPerson()}</div>
                        <div className='mb-1'>{item.phonesValue()}</div>
                        {item.position()}
                      </div>
                    </div>
                    <Separator className='mt-3' />
                  </div>
                )
              })}
            </div>,
          ),
        )}
      </>
    </>
  )
}
