export const isBase64Png = (str = '') => {
  const pngPrefix = 'data:image/png;base64,'
  let base64Data = str

  // if string starts with png prefix, remove it
  if (str.startsWith(pngPrefix)) {
    base64Data = str.slice(pngPrefix.length)
  }

  // Check is string a valid base64
  const base64Pattern = /^[A-Za-z0-9+/]+[=]{0,2}$/
  if (!base64Pattern.test(base64Data)) return false

  try {
    const decodedData = atob(base64Data)

    // Check if the decoded string starts with a PNG header
    // PNG file always starts with 8 bytes: [0x89, 'P', 'N', 'G', 0x0D, 0x0A, 0x1A, 0x0A]
    const pngHeader = [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]
    for (let i = 0; i < pngHeader.length; i++) {
      if (decodedData.charCodeAt(i) !== pngHeader[i]) {
        return false
      }
    }
    return true
  } catch (error) {
    return false
  }
}

export const isBase64 = (str = ''): boolean => {
  try {
    const base64Pattern = /^[A-Za-z0-9+/]+[=]{0,2}$/
    return base64Pattern.test(str)
  } catch (error) {
    console.error('Error when decoding Base64:', error)
    return false
  }
}

export const isBase64Pdf = (base64String?: string) => {
  try {
    const binaryData = atob(base64String || '')

    return binaryData.startsWith('%PDF-')
  } catch (error) {
    console.error('Error when decoding Base64:', error)
    return false
  }
}

export const decodeBase64Image = (base64Str = ''): string => {
  const base64Data = base64Str.replace(/^data:image\/[a-zA-Z]+;base64,/, '')

  // Check if the string is base64 format
  if (!isBase64(base64Data)) {
    console.log('Invalid base64 string')
    return ''
  }

  try {
    const byteCharacters = atob(base64Data)
    const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0))
    const byteArray = new Uint8Array(byteNumbers)

    const mimeType = base64Str.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,/)?.[1] || 'image/png'
    const blob = new Blob([byteArray], { type: mimeType })

    return URL.createObjectURL(blob)
  } catch (error) {
    console.log('Failed to decode base64 string:', error)
    return ''
  }
}

export const decodeBase64Pdf = (base64Str = ''): string => {
  // Check if the string is base64 format
  if (!isBase64Pdf(base64Str)) {
    console.log('Invalid base64 string')
    return ''
  }

  try {
    const dataPrefix = 'data:application/pdf;base64,'
    let pdfString = base64Str

    if (!base64Str.startsWith(dataPrefix)) {
      pdfString = `${dataPrefix}${base64Str}`
    }

    return pdfString
  } catch (error) {
    console.log('Failed to decode base64 string:', error)
    return ''
  }
}
