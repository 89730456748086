import { BarcodeScanner, TextResult } from 'dynamsoft-javascript-barcode'
import Router from 'next/router'
import React from 'react'

import { toastEnhanced } from 'src/components/common'

interface VideoDecodeProps {
  onScanResult: (value: string, result: TextResult) => void
}

interface VideoDecodeState {
  isShowTorchIcon: boolean
  flashlightOn: boolean
}

class VideoDecode extends React.Component<VideoDecodeProps, VideoDecodeState> {
  pScanner: Promise<BarcodeScanner> | null = null
  elRef: React.RefObject<HTMLDivElement | null> = React.createRef()

  async componentDidMount() {
    try {
      const scanner = await (this.pScanner = BarcodeScanner.createInstance())
      // Should judge if scanner is destroyed after 'await', as in development React runs setup and cleanup one extra time before the actual setup in Strict Mode.
      if (scanner.isContextDestroyed()) {
        return
      }
      if (this.elRef.current) {
        scanner.setVideoFit('cover')
        await scanner.setUIElement(this.elRef.current)
      }
      // Should judge if scanner is destroyed after 'await', as in development React runs setup and cleanup one extra time before the actual setup in Strict Mode.
      if (scanner.isContextDestroyed()) {
        return
      }

      scanner.onUniqueRead = (value, result) => {
        this.props.onScanResult(value, result)
      }

      await scanner.open()
    } catch (err) {
      const error = err as Error
      toastEnhanced({ title: error.message }, { type: 'error' })

      Router.back()
      // throw error;
    }
  }

  async componentWillUnmount() {
    if (this.pScanner) {
      ;(await this.pScanner).destroyContext()
      console.log('BarcodeScanner Component Unmount')
    }
  }

  shouldComponentUpdate() {
    // Never update UI after mount, dbr.js sdk use native way to bind event, update will remove it.
    return false
  }

  render() {
    return <div ref={this.elRef} className='dce-video-container' />
  }
}

export default VideoDecode
