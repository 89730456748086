import { useCallback, useState } from 'react'

import { UpdateCustomerForm } from 'src/components/common'

import { useCustomersDetailsFetch, useCustomersDetailsUpdate, useCustomersStaticDataFetch } from 'src/bus/customers'
import { useStaticDataCustomerLabelsFetch } from 'src/bus/staticData'

export const CustomersEdit = () => {
  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()
  const { onUpdateCustomersDetails, loadingCustomersDetailsUpdate } = useCustomersDetailsUpdate()

  const [selectedBusinessTypeId, setSelectedBusinessTypeId] = useState<string>()

  const { customersFilters, loadingCustomerStaticDataFetch } = useCustomersStaticDataFetch()
  const { staticDataCustomerLabels, loadingStaticDataCustomerLabelsFetch } =
    useStaticDataCustomerLabelsFetch(selectedBusinessTypeId)

  const onSelectBusinessTypeId = useCallback((id: string) => setSelectedBusinessTypeId(id), [])

  const disabledFields = {
    title: true,
    index: true,
    country: true,
    city: true,
    street: true,
    houseNumber: true,
    phonePrefix: true,
    phoneNumber: true,
    vat: true,
    email: true,
    contactPersons: true,
  }

  return (
    <div className='flex h-full flex-col py-2' data-test-id='customers-edit'>
      <UpdateCustomerForm
        onSubmit={onUpdateCustomersDetails}
        filters={customersFilters}
        onSelectBusinessTypeId={onSelectBusinessTypeId}
        customerLabelsOptions={staticDataCustomerLabels.customerLabels}
        loadingCustomerLabelsFetch={loadingStaticDataCustomerLabelsFetch}
        loadingFetchStaticData={loadingCustomerStaticDataFetch}
        loadingFetch={loadingCustomersDetailsFetch}
        loadingUpdate={loadingCustomersDetailsUpdate}
        initialValues={customersDetails}
        disabledFields={disabledFields}
      />
    </div>
  )
}
