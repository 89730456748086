import deepEqual from 'deep-equal'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo, useRef } from 'react'
import SignaturePad, { ReactSignatureCanvasProps } from 'react-signature-canvas'
import { Point } from 'signature_pad'
import { useWindowSize } from 'usehooks-ts'
import { cn } from 'utils/helpers'

import { Button, Typography } from 'src/components/ui'

import { useOrientation } from 'src/hooks'

import st from './Styles.module.css'

type InputSignatureProps = ReactSignatureCanvasProps & {
  className?: string
  canvasClassName?: string
  onChange: (value: string | void) => void
  disabled?: boolean
  'aria-describedby'?: string
  'aria-invalid'?: boolean
}

const DEFAULT_SIGNATURE_HEIGHT = 150
const WIDTH_GAP = 16
const EXTRA_HEIGHT = 208 // calc from all page elements except canvas

export const InputSignature = ({
  className = '',
  canvasClassName = '',
  onChange,
  disabled = false,
  ...rest
}: InputSignatureProps) => {
  const { t } = useTranslation()
  const { width, height } = useWindowSize()
  const orientation = useOrientation()

  const signCanvas = useRef<SignaturePad | null>(null)
  const canvasValue = useRef<Point[][]>([])

  const size = useMemo(() => {
    return {
      width: width - WIDTH_GAP,
      height: orientation.isLandscape ? height - EXTRA_HEIGHT : DEFAULT_SIGNATURE_HEIGHT,
    }
  }, [orientation, width, height])

  const rootClasses = cn('w-full', className)
  const canvasClasses = cn(st['canvas'], canvasClassName, {
    [st['disabled']]: disabled,
  })

  const onUpdate = () => {
    canvasValue.current = signCanvas.current?.toData() || []
    return signCanvas.current?.toDataURL('image/png')
  }

  const onClear = () => {
    canvasValue.current = []
    onChange(signCanvas.current?.clear())
  }

  useEffect(() => {
    if (!deepEqual(signCanvas.current?.toData(), canvasValue.current)) {
      onChange(onUpdate())
    }
    signCanvas.current?.fromData(canvasValue.current)
  }, [orientation, onChange])

  return (
    <div className={rootClasses}>
      <div className='flex w-full flex-auto flex-col rounded-[4px] bg-white'>
        <div className='p-2'>
          <Typography variant='secondary'>{t('returns:customer_signature')}</Typography>
        </div>
        <div
          className={st['canvas-container']}
          aria-describedby={rest['aria-describedby']}
          aria-invalid={rest['aria-invalid']}
        >
          <SignaturePad
            ref={signCanvas}
            onEnd={() => onChange(onUpdate())}
            canvasProps={{
              ...size,
              className: canvasClasses,
            }}
          />
        </div>
      </div>
      <div className='my-2 w-full'>
        <Button variant='outline' onClick={onClear} block>
          {t('buttons:clear_signature')}
        </Button>
      </div>
    </div>
  )
}
