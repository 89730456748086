import {
  HttpResponse,
  ManagePointsItemsType,
  managePointsSchemaList,
  ManageTourParams,
  ManageToursDaysItemsType,
  manageToursDaysSchemaItems,
} from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const manageToursService = Object.freeze({
  fetchManagePoints: async ({ params }: ManageTourParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ManagePointsItemsType>>('/map/points', { params })
    zodValidateResponse(managePointsSchemaList, response.data.data)
    return response
  },
  fetchDays: async ({ params }: ManageTourParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ManageToursDaysItemsType>>('/map/options/days', {
      params,
    })
    zodValidateResponse(manageToursDaysSchemaItems, response.data.data)
    return response
  },
})
