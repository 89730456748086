import React, { PropsWithChildren, useRef, useEffect, useState, useCallback } from 'react'
import { useOnClickOutside } from 'usehooks-ts'
import { FLOATING_PANEL_ANCHORS } from 'utils/constants'

type FloatingPanelProps = {
  children: React.ReactNode
  anchors?: number[]
}

type FloatingPanelType = PropsWithChildren<FloatingPanelProps>

export const FloatingPanel = ({ children, anchors = FLOATING_PANEL_ANCHORS.DEFAULT, ...rest }: FloatingPanelType) => {
  const panelRef = useRef<HTMLDivElement | null>(null)
  const isDraggingRef = useRef<boolean>(false)
  const startYRef = useRef<number>(anchors[0])
  const translateYRef = useRef<number>(anchors[0])

  const [translateY, setTranslateY] = useState<number>(anchors[0])

  const onClickOutside = () => {
    if (panelRef?.current) {
      setTranslateY(anchors[0])
      translateYRef.current = anchors[0]
    }

    if (panelRef.current) {
      panelRef.current.blur()
    }
  }

  useOnClickOutside(panelRef as React.RefObject<HTMLElement>, onClickOutside)

  const enhancedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement<{ onClick?: (e: React.MouseEvent) => void }>(child)) {
      const childProps = {
        ...child.props,
        onClick: (e: React.MouseEvent) => {
          child.props.onClick?.(e)
          onClickOutside()
        },
      }

      return React.cloneElement(child, childProps)
    }
    return child
  })

  const onDragStart = useCallback((clientY: number) => {
    isDraggingRef.current = true
    startYRef.current = clientY
  }, [])

  const onDragMove = useCallback(
    (clientY: number) => {
      if (!isDraggingRef.current) {
        return
      }
      const diff = clientY - startYRef.current
      const newTranslateY = translateYRef.current - diff
      const maxTranslateY = Math.max(...anchors)
      const minTranslateY = Math.min(...anchors)
      const clampedTranslateY = Math.max(minTranslateY, Math.min(maxTranslateY, newTranslateY))

      setTranslateY(clampedTranslateY)
    },
    [anchors],
  )

  const onDragEnd = useCallback(() => {
    isDraggingRef.current = false

    const closestAnchor = anchors.reduce((prev, curr) =>
      Math.abs(curr - translateYRef.current) < Math.abs(prev - translateYRef.current) ? curr : prev,
    )

    translateYRef.current = closestAnchor
    setTranslateY(closestAnchor)
  }, [anchors])

  const onHandleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    onDragStart(e.clientY)

    const onHandleMouseMove = (e: MouseEvent) => {
      onDragMove(e.clientY)
    }

    const onHandleMouseUp = () => {
      onDragEnd()
      document.removeEventListener('mousemove', onHandleMouseMove)
      document.removeEventListener('mouseup', onHandleMouseUp)
    }

    document.addEventListener('mousemove', onHandleMouseMove)
    document.addEventListener('mouseup', onHandleMouseUp)
  }

  const onHandleMouseMove = (e: React.MouseEvent) => {
    onDragMove(e.clientY)
  }

  const onHandleTouchStart = (e: React.TouchEvent) => {
    e.preventDefault()

    onDragStart(e.touches[0].clientY)

    const onHandleTouchMove = (e: TouchEvent) => {
      onDragMove(e.touches[0].clientY)
    }

    const handleTouchEnd = () => {
      onDragEnd()
      document.removeEventListener('touchmove', onHandleTouchMove)
      document.removeEventListener('touchend', handleTouchEnd)
    }

    document.addEventListener('touchmove', onHandleTouchMove, { passive: false })
    document.addEventListener('touchend', handleTouchEnd)
  }

  const onHandleTouchMove = (e: React.TouchEvent) => {
    onDragMove(e.touches[0].clientY)
  }

  useEffect(() => {
    const onHandleTouchMove = (e: TouchEvent) => {
      if (isDraggingRef.current) {
        e.preventDefault()
      }
    }

    document.addEventListener('touchmove', onHandleTouchMove, { passive: false })

    return () => {
      document.removeEventListener('touchmove', onHandleTouchMove)
    }
  }, [])

  useEffect(() => {
    translateYRef.current = translateY
  }, [translateY])

  return (
    <div className='h-[85px]'>
      <div
        {...rest}
        ref={panelRef}
        data-test-id='floating-panel'
        className='fixed bottom-0 left-0 right-0 top-auto z-50 mx-2 my-0 flex flex-col overflow-hidden rounded-t-md bg-white p-0 shadow-level-1 transition-transform duration-300 ease-in-out'
        style={{
          transform: `translateY(calc(100% - ${translateY}px)`,
          height: `${anchors[anchors.length - 1]}px`,
        }}
        onMouseUp={onDragEnd}
        onMouseDown={onHandleMouseDown}
        onMouseMove={onHandleMouseMove}
        onTouchStart={onHandleTouchStart}
        onTouchEnd={onDragEnd}
        onTouchMove={onHandleTouchMove}
      >
        <div className='mx-auto mb-4 mt-2 h-1 w-7 flex-none cursor-grab rounded-full bg-disabled-bg' />
        <div className='flex-grow'>{enhancedChildren}</div>
      </div>
    </div>
  )
}
