import { useTranslation } from 'next-i18next'
import { MouseEventHandler, useMemo } from 'react'

import { Dialog, DialogViewsSwitcher } from 'src/components/common'
import { Button, Icon } from 'src/components/ui'

import { LocationType } from 'src/models'

import { useAuthProfile } from 'src/bus/auth'
import { useVisitsFetch } from 'src/bus/visits'

import { useCurrentLocation } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { FAR_AWAY_DISTANCE, GET_ENUMS, orNull } from 'src/utils'

type CreateReportButtonProps = {
  onSubmit: () => Promise<void>
  loadingSubmit: boolean
  disabledBtn: boolean
}

export const CreateReportButton = ({ onSubmit, loadingSubmit, disabledBtn }: CreateReportButtonProps) => {
  const { t } = useTranslation()
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { isRoleServiceAgent, isRoleProspector, isCountryDE } = useAuthProfile()
  const { geoLocation, loadingLocation } = useCurrentLocation()
  const { visitFirst, loadingVisitsFetch } = useVisitsFetch()

  const isValidLocation = (location: LocationType) => location.lat && location.lng

  const distance = useMemo(() => {
    if (loadingLocation || loadingVisitsFetch || !geoLocation || !visitFirst?.location) {
      return 0
    }

    return isValidLocation(geoLocation) && isValidLocation(visitFirst.location)
      ? google.maps.geometry.spherical.computeDistanceBetween(geoLocation, visitFirst.location)
      : 0
  }, [loadingLocation, loadingVisitsFetch, geoLocation, visitFirst?.location])

  const isLoading = loadingLocation || loadingVisitsFetch

  const isDistanceFarAway = +distance?.toFixed(0) > FAR_AWAY_DISTANCE
  const isNeedDistance = isDistanceFarAway && (isRoleServiceAgent || (isRoleProspector && isCountryDE))

  const onClickHandle: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()

    if (isNeedDistance) {
      setOnPageDialog(GET_ENUMS.dialog.farAwayReport, true)
    } else {
      await onSubmit()
    }
  }

  return (
    <>
      <Dialog
        open={onPageDialogs[GET_ENUMS.dialog.farAwayReport]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.dialog.farAwayReport, open)}
      >
        <DialogViewsSwitcher name={GET_ENUMS.dialog.farAwayReport} params={{ onSubmitForm: onSubmit, loadingSubmit }} />
      </Dialog>
      <Button
        block
        disabled={disabledBtn && isNeedDistance}
        loading={loadingSubmit || isLoading}
        onClick={onClickHandle}
        variant={isNeedDistance ? 'cta' : 'default'}
      >
        <span className='mr-1 flex'>{orNull(isNeedDistance, <Icon name='Location' color='white' />)}</span>
        {t('buttons:create_report')}
      </Button>
    </>
  )
}
