import { useTranslation } from 'next-i18next'
import NextImage from 'next/image'
import Link from 'next/link'

import { book } from 'src/init/book'

import { PlanMap } from 'src/components/common'
import { Button, Icon, Skeleton, Typography } from 'src/components/ui'

import { useTourRoutesFetch } from 'src/bus/tour'

import { useCurrentLocation } from 'src/hooks'

import { getHasNoData, ternary } from 'src/utils'

export const PlanForTodayMap = () => {
  const { t } = useTranslation()
  const { tourRoutes, loadingTourRoutesFetch } = useTourRoutesFetch()
  const { geoLocation, isGeolocationAvailable, isGeolocationEnabled, positionError } = useCurrentLocation()

  const renderError = () => {
    const errorText = positionError?.message
      ? 'geolocation_user_denied'
      : !isGeolocationEnabled
        ? 'geolocation_not_enabled'
        : !isGeolocationAvailable
          ? 'geolocation_not_available'
          : ''

    return (
      <div className='flex h-full min-h-[272px] flex-col items-center justify-center rounded-sm bg-white text-center'>
        {ternary(
          errorText,
          <Typography variant='title' color='gray-10'>
            {errorText && t(`news-and-plans:${errorText}`)}
          </Typography>,
          <>
            <Typography className='font-semibold'>{t('news-and-plans:no_plans')}</Typography>
            <Typography variant='secondary' color='gray-7'>
              {t('news-and-plans:contact_manager')}
            </Typography>
          </>,
        )}
        <div className='mt-4'>
          <NextImage
            src='/assets/images/PlansForTodayEmpty.png'
            width={60}
            height={60}
            alt='PlansForTodayEmpty'
            priority
          />
        </div>
      </div>
    )
  }

  return (
    <div className='relative flex flex-auto flex-col px-2'>
      {ternary(
        loadingTourRoutesFetch,
        <Skeleton className='h-full' />,
        <>
          {ternary(
            getHasNoData(tourRoutes.items) || !geoLocation,
            renderError(),
            <PlanMap places={tourRoutes.items} />,
          )}
        </>,
      )}
      <Button className='absolute right-4 top-2 bg-white' size='icon-md-plus' variant='ghost'>
        <Link href={book.manageTours}>
          <Icon name='resize-out' color='gray-7' />
        </Link>
      </Button>
    </div>
  )
}
