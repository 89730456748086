import {
  HttpResponse,
  StaticDataCustomerLabelsType,
  StaticDataFetchParams,
  StaticDataInnerShape,
  StaticDataSectorsType,
  StaticDataZonesType,
} from 'src/models'

import { httpAxiosProvider } from 'src/api'

export const staticDataService = Object.freeze({
  fetchStaticData: ({ params }: StaticDataFetchParams) => {
    return httpAxiosProvider.get<HttpResponse<StaticDataInnerShape>>('/options', { params })
  },
  fetchStaticDataZones: ({ params }: StaticDataFetchParams) => {
    return httpAxiosProvider.get<HttpResponse<StaticDataZonesType>>('/options/zones', { params })
  },
  fetchStaticDataSectors: ({ params }: StaticDataFetchParams) => {
    return httpAxiosProvider.get<HttpResponse<StaticDataSectorsType>>('/options/sectors', {
      params,
    })
  },
  fetchStaticDataCustomerLabels: ({ params }: StaticDataFetchParams) => {
    return httpAxiosProvider.get<HttpResponse<StaticDataCustomerLabelsType>>('/options/customer-labels', { params })
  },
})
