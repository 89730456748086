import QRCode from 'react-qr-code'

import { Typography } from 'src/components/ui'

type ShowQRCodeProps = {
  value?: string
  size?: number
  viewBox?: string
  level?: string // Can be one of 'L,M,H,Q'
}

const MAX_QR_LENGTH = 4000

export const ShowQRCode = ({ value = '', size = 256, viewBox = '0 0 256 256', level = 'H' }: ShowQRCodeProps) => {
  if (value.length > MAX_QR_LENGTH) {
    return (
      <div className='flex justify-center'>
        <Typography variant='secondary'>Error: The URL is too long for a QR code</Typography>
      </div>
    )
  }

  return <QRCode size={size} className='h-auto w-full max-w-full' value={value} viewBox={viewBox} level={level} />
}
