import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { showCatchErrors } from 'utils/helpers'

import { FormSubmitParams, FormUpdateCustomersLocationType, HttpErrorType } from 'src/models'

import { useCustomerQuery } from 'src/bus/customers'
import { visitsKeys } from 'src/bus/visits'

import { useToast } from 'src/hooks'

type UpdateCustomersCbType = (data: FormSubmitParams<FormUpdateCustomersLocationType>, id?: number) => void
type DeleteCustomersCbType = (id?: number) => void

type UseCustomersUpdateLocationType = {
  loadingCustomersPropsUpdate: boolean
  onUpdateCustomersLocation: UpdateCustomersCbType
  onDeleteCustomersLocation: DeleteCustomersCbType
}

export const useCustomersUpdateLocation = (): UseCustomersUpdateLocationType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const router = useRouter()
  const showToast = useToast()

  const id = Number(router.query.customerId || router.query.id)

  const { onUpdateCustomer } = useCustomerQuery(id)

  const onUpdateCustomersLocation = useCallback<UpdateCustomersCbType>(
    async (data, id) => {
      onUpdateCustomer.mutate(
        {
          params: { id },
          data: { realLocation: data.values.realLocation },
          updateMethod: 'partial',
        },
        {
          onSuccess: () => {
            showToast.success({ title: t('notifications:update_location_point') })
            setTimeout(() => {
              router.back()
            }, 500)
          },
          onError: (error) => {
            showCatchErrors(error, showToast)
          },
          onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: visitsKeys.list() })
          },
        },
      )
    },
    [onUpdateCustomer, t, showToast, router, queryClient],
  )

  const onDeleteCustomersLocation = useCallback<DeleteCustomersCbType>(
    (customerId) => {
      onUpdateCustomer.mutate(
        {
          data: { realLocation: null },
          params: { id: customerId },
          updateMethod: 'partial',
        },
        {
          onSuccess: () => {
            showToast.success({ title: t('notifications:delete_location_point') })
          },
          onError: (error) => {
            const errs = (error as HttpErrorType).errors || {}
            const entries = Object.entries(errs)

            if (entries.length > 0) {
              const [[title, body]] = entries
              showToast.error({ title, body })
            }
          },
          onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: visitsKeys.list() })
          },
        },
      )
    },
    [onUpdateCustomer, t, showToast, queryClient],
  )

  return {
    onUpdateCustomersLocation,
    onDeleteCustomersLocation,
    loadingCustomersPropsUpdate: onUpdateCustomer.isPending,
  }
}
