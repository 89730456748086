import { useTranslation } from 'next-i18next'

import { HistoryCard, HistoryItemRow, WrappedNodeDefault, HistoryItem, HistoryEllipsis } from 'src/components/common'
import { Typography } from 'src/components/ui'

import { HistoryVisitType } from 'src/models'

import { getTranslate, ternary } from 'src/utils'

type HistoryVisitItemProps = {
  item: HistoryVisitType
  showDate: boolean
  index: number
}

export const HistoryVisitItem = ({ item, index, showDate }: HistoryVisitItemProps) => {
  const { t } = useTranslation()

  const getHistoryT = getTranslate('history', t)

  return (
    <HistoryItem date={item.date} index={index} showDate={showDate}>
      <HistoryCard variant='visit'>
        <Typography className='font-semibold'>{getHistoryT('visit')}</Typography>
        <HistoryItemRow label={getHistoryT('status')} description={WrappedNodeDefault(item.visitStatus?.label)} />
        <HistoryItemRow
          label={getHistoryT('employee')}
          description={
            <div className='flex items-baseline gap-2'>
              {WrappedNodeDefault(item.employee)}
              <Typography color='gray-7'>{WrappedNodeDefault(item.employeeRole)}</Typography>
            </div>
          }
        />
        <HistoryItemRow
          label={getHistoryT('comment')}
          description={
            <>
              {ternary(
                item.comment,
                <HistoryEllipsis content={item.comment} rows={2} direction='end' />,
                WrappedNodeDefault(''),
              )}
            </>
          }
        />
      </HistoryCard>
    </HistoryItem>
  )
}
