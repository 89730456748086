import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { isNil } from 'ramda'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { IssueImagesForm } from 'src/components/common/Forms/IssueImages'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormFields,
  FormItem,
  FormLabel,
  FormMessage,
  Loader,
  Typography,
} from 'src/components/ui'

import { FormSubmitFn, FormCustomerIssueType, FormCustomerIssueEnum, StaticDataItemType, SelectItem } from 'src/models'

import { formShapes } from 'src/bus/forms'
import { useOtrsImagesUpload } from 'src/bus/otrs'

import { getHasData, messages, orNull, REASON_DIDNT_REACH } from 'src/utils'

type CustomerIssueFormType = {
  loadingCreate?: boolean
  onSubmit?: FormSubmitFn<FormCustomerIssueType>
  reasons: StaticDataItemType[]
  loadingReasons?: boolean
  onSelectReasonId: (id: number) => void
  clarifications?: StaticDataItemType[]
  loadingClarifications?: boolean
  onSelectClarificationId: (id?: number) => void
  fields?: string[]
  loadingFields?: boolean
  orderOptions?: StaticDataItemType[]
  loadingOrderOptions?: boolean
  orderProductOptions?: StaticDataItemType[]
  loadingOrderProductOptions?: boolean
  onSelectOrderId: (id?: number) => void
  initialValues?: FormCustomerIssueType | null
}

export const CustomerIssueForm = ({
  loadingCreate = false,
  reasons = [],
  loadingReasons = false,
  onSelectReasonId,
  clarifications = [],
  loadingClarifications = false,
  onSelectClarificationId,
  fields = [],
  loadingFields = false,
  orderOptions = [],
  loadingOrderOptions = false,
  orderProductOptions = [],
  loadingOrderProductOptions = false,
  onSelectOrderId,
  onSubmit,
}: CustomerIssueFormType) => {
  const { t } = useTranslation()
  const { onUploadImages, otrsImages, loadingOtrsImagesUpload } = useOtrsImagesUpload()

  const formProps = useForm<FormCustomerIssueType>({
    defaultValues: { ...formShapes.customerIssue.shape },
    resolver: zodResolver(formShapes.customerIssue.schema()),
    mode: 'onChange',
  })

  const { control, watch, clearErrors, setValue, handleSubmit, setError, reset } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit?.({ values, acts: { setError, reset } })
  })

  const watchedFields = watch()
  const selectedReasonId = watchedFields?.reason?.id
  const selectedOrderId = watchedFields?.order?.id

  const isFirstReason = watchedFields?.reason?.value === REASON_DIDNT_REACH

  const onClearFieldsCommon = useCallback(() => {
    setValue('order', null)
    setValue('orderProduct', null)
    setValue('correctAddress', '')
    setValue('vin', '')
    setValue('brand', '')
    setValue('article', '')
    setValue('comment', '')
    setValue('images', [])
    clearErrors()
  }, [setValue, clearErrors])

  const onClearFieldsClarification = useCallback(() => {
    setValue('clarification', null)
    onClearFieldsCommon()
  }, [setValue, onClearFieldsCommon])

  const onImageDelete = () => {
    setValue('images', [])
  }

  useDeepCompareEffect(() => {
    if (otrsImages) {
      setValue('images', otrsImages)
    }
  }, [otrsImages, setValue])

  useEffect(() => {
    if (isNil(watchedFields.reason)) {
      return
    }

    onSelectReasonId(watchedFields.reason.id)
    onSelectClarificationId(undefined)
    onClearFieldsClarification()
  }, [onSelectReasonId, onSelectClarificationId, watchedFields.reason, onClearFieldsClarification])

  useEffect(() => {
    if (isNil(watchedFields.clarification)) {
      return
    }

    onSelectClarificationId(watchedFields.clarification.id)
    onClearFieldsCommon()
  }, [onSelectClarificationId, watchedFields.clarification, onClearFieldsCommon])

  useEffect(() => {
    if (isNil(watchedFields.order)) {
      return
    }

    onSelectOrderId(watchedFields.order.id)
  }, [onSelectOrderId, watchedFields.order])

  const fieldsObj: Record<
    string,
    {
      type: 'select' | 'input' | 'images'
      options?: SelectItem[]
      loading?: boolean
      disabled?: boolean
    }
  > = {
    order: {
      type: 'select',
      options: orderOptions,
      loading: loadingOrderOptions,
      disabled: loadingCreate,
    },
    orderProduct: {
      type: 'select',
      options: orderProductOptions,
      loading: loadingOrderProductOptions,
      disabled: loadingCreate || !selectedOrderId,
    },
    correctAddress: {
      type: 'input',
      disabled: loadingCreate,
    },
    article: {
      type: 'input',
      disabled: loadingCreate,
    },
    brand: {
      type: 'input',
      disabled: loadingCreate,
    },
    vin: {
      type: 'input',
      disabled: loadingCreate,
    },
    vinOrPlateNumber: {
      type: 'input',
      disabled: loadingCreate,
    },
    images: {
      type: 'images',
      loading: loadingOtrsImagesUpload,
      disabled: loadingCreate,
    },
  }

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        onSubmit={onSubmitForm}
        scroll
        footer={
          <>
            <div className='px-2'>
              <Button
                block
                type='submit'
                loading={loadingCreate}
                disabled={!selectedReasonId || loadingOtrsImagesUpload}
              >
                {t('customers:issue_dialog.send')}
              </Button>
            </div>
            <div className='mt-2 text-center'>
              <Typography variant='caption' color='gray-6'>
                {t('customers:issue_dialog.required_fields')}
              </Typography>
            </div>
          </>
        }
      >
        <FormField
          control={control}
          name={FormCustomerIssueEnum.reason}
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Typography variant='secondary' className='font-semibold'>
                  {t(messages.customerIssue.reason.label)}
                </Typography>
              </FormLabel>
              <FormControl>
                <FormFields.InputSelect
                  {...field}
                  position='relative'
                  placeholder={t(messages.customerIssue.reason.placeholder)}
                  options={reasons}
                  loading={loadingReasons}
                  hasDisabled={loadingCreate}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
        {orNull(
          getHasData(clarifications),
          <FormField
            control={control}
            name={FormCustomerIssueEnum.clarification}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormFields.InputSelect
                    {...field}
                    position='relative'
                    placeholder={t(messages.customerIssue.clarification.placeholder)}
                    options={clarifications}
                    loading={loadingClarifications}
                    hasDisabled={loadingCreate}
                  />
                </FormControl>
                <FormMessage className='absolute' />
              </FormItem>
            )}
          />,
        )}
        {orNull(
          loadingClarifications || loadingFields,
          <div className='mb-4'>
            <Loader type='relative' />
          </div>,
        )}
        {fields.map((el) => {
          const fieldEl = el as keyof typeof FormCustomerIssueEnum
          const item = fieldsObj[fieldEl]

          if (item.type === 'select') {
            return (
              <FormField
                key={fieldEl}
                control={control}
                name={fieldEl as any}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Typography variant='secondary' className='font-semibold'>
                        {t(messages.customerIssue[fieldEl].label)}
                      </Typography>
                    </FormLabel>
                    <FormControl>
                      <FormFields.InputSelect
                        {...field}
                        position='relative'
                        isSearchable
                        placeholder={t(messages.customerIssue[fieldEl].placeholder)}
                        options={item.options}
                        loading={item.loading}
                        hasDisabled={item.disabled}
                      />
                    </FormControl>
                    <FormMessage className='absolute' />
                  </FormItem>
                )}
              />
            )
          }

          if (item.type === 'input') {
            return (
              <FormField
                key={fieldEl}
                control={control}
                name={fieldEl as any}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Typography variant='secondary' className='font-semibold'>
                        {t(messages.customerIssue[fieldEl].label)}
                      </Typography>
                    </FormLabel>
                    <FormControl>
                      <FormFields.InputBase
                        {...field}
                        placeholder={t(messages.customerIssue[fieldEl].placeholder)}
                        disabled={item.disabled}
                      />
                    </FormControl>
                    <FormMessage className='absolute' />
                  </FormItem>
                )}
              />
            )
          }

          if (item.type === 'images') {
            return (
              <IssueImagesForm
                key={fieldEl}
                onSubmit={onUploadImages}
                loadingUpload={loadingOtrsImagesUpload}
                onImageDelete={onImageDelete}
              />
            )
          }
        })}
        {selectedReasonId && (
          <FormField
            control={control}
            name={FormCustomerIssueEnum.comment}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Typography variant='secondary' className='font-semibold'>
                    {t(messages.customerIssue.comment.label)}
                  </Typography>
                </FormLabel>
                <FormControl>
                  <FormFields.InputTextArea
                    {...field}
                    placeholder={t(messages.customerIssue.comment[isFirstReason ? 'reasonPlaceholder' : 'placeholder'])}
                    disabled={loadingCreate}
                    rows={3}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
      </FormFields.FormGeneral>
    </Form>
  )
}
