import { useCallback, useEffect, useRef, useState } from 'react'
import { showCatchErrors } from 'utils/helpers'

import { ParcelsQRCodeType, ParcelType } from 'src/models'

import { useParcelsQRCodeFetch } from 'src/bus/parcels'

import { useToast } from 'src/hooks'

type PrintQRCodeCb = () => Promise<void>

type UseParcelsQRCodePrintType = {
  parcelsQRCode: ParcelsQRCodeType | null
  loadingFetchQRCode: boolean
}

export const useParcelsQRCodePrint = (parcelItem?: ParcelType | null): UseParcelsQRCodePrintType => {
  const { onParcelsQRCodeFetch } = useParcelsQRCodeFetch()
  const showToast = useToast()
  const [parcelsQRCode, setParcelsQRCode] = useState<ParcelsQRCodeType | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const hasPrinted = useRef(false)

  const onPrintQRCode = useCallback<PrintQRCodeCb>(async () => {
    try {
      setIsLoading(true)

      const data = await onParcelsQRCodeFetch(parcelItem?.id)
      setParcelsQRCode(data)
    } catch (error) {
      showCatchErrors(error, showToast)
    } finally {
      setIsLoading(false)
    }
  }, [onParcelsQRCodeFetch, parcelItem?.id, showToast])

  useEffect(() => {
    if (hasPrinted.current) return

    if (parcelItem?.id || parcelItem?.isAvailableQrCode) {
      ;(async () => {
        hasPrinted.current = true
        await onPrintQRCode()
      })()
    }
  }, [onPrintQRCode, parcelItem?.id, parcelItem?.isAvailableQrCode])

  return {
    parcelsQRCode,
    loadingFetchQRCode: isLoading,
  }
}
