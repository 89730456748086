import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader'
import { type ClassValue, clsx } from 'clsx'
import { TFunction } from 'i18next'
import { i18n } from 'next-i18next'
import {
  pipe,
  map,
  prop,
  flatten,
  length,
  equals,
  dissoc,
  isNil,
  complement,
  keys,
  curry,
  assoc,
  reduce,
  find,
  pathEq,
  split,
  last,
  reject,
  either,
  pluck,
  pick,
  join,
  toLower,
  evolve,
  filter,
  props,
} from 'ramda'
import { twMerge } from 'tailwind-merge'

import { TStatusItemVariants } from 'src/components/common/StatusItem'

import {
  SelectItem,
  ServerErrorFormatType,
  HttpResponseError,
  SwipeActionType,
  ServerErrorFieldFormatType,
  HttpErrorFieldFormatType,
  ServerErrorRawFormatType,
  HttpErrorType,
} from 'src/models'

import { countryIcons } from 'src/data'

import { ERRORS_VALIDATION_KEY, MAX_MARKER_WIDTH, REGEXP_UPPER_LETTER } from './constants'

type RemoveUndefinedType = null | undefined | Record<string, any> | []

export const removeUndefinedValues = (state: RemoveUndefinedType): RemoveUndefinedType => {
  if (typeof state === 'undefined') return null
  if (Array.isArray(state)) return state.map(removeUndefinedValues)
  if (typeof state === 'object' && state !== null) {
    return Object.entries(state).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: removeUndefinedValues(value),
      }
    }, {})
  }

  return state
}

export const getFormatErrorValidation = (error: ServerErrorRawFormatType): ServerErrorFormatType => {
  if (!error.errors) {
    return { ...error, errors: void 0 }
  }

  const errFieldsArr = Object.entries(error.errors)

  const newFields = errFieldsArr.reduce((acc, item) => {
    const [key, details] = item

    const alias = `${ERRORS_VALIDATION_KEY}${details.alias}`

    const message = i18n?.exists(alias) ? i18n?.t(alias) : details.desc

    return { ...acc, [key]: message }
  }, {})

  return { ...error, errors: newFields }
}

export const isHttpErrorType = (error: unknown): error is HttpErrorFieldFormatType => {
  return typeof error === 'object' && error !== null && 'errors' in error
}

export const formatNotificationMessage = (errorObj: ServerErrorFieldFormatType = {}) => {
  const key = Object.keys(errorObj)[0]
  const message = errorObj[key]

  if (!message) {
    console.log('formatNotificationMessage - no message!')
    return { title: 'Error', body: 'Unknown error occurred.' }
  }

  return {
    title: message || 'Error',
  }
}

export const showCatchErrors = (err: unknown, showToast: any) => {
  const error = err as HttpErrorType
  const errs = error?.errors || {}

  const entries = Object.entries(errs)

  if (entries.length > 0) {
    const [[title, body]] = entries
    showToast.error({ title, body })
  } else {
    showToast.error({ title: error.type, body: error.message })
  }
}

export const checkTypeApiError = (value: unknown) => value instanceof Object && 'error' in value

const statusPath = ['status', 'value']

export const getInProgressStatus = pathEq('in_progress', statusPath)
export const getPackedStatus = pathEq('packed', statusPath)
export const getReadyStatus = pathEq('ready', statusPath)
export const getRejectStatus = pathEq('reject', statusPath)
export const getPlanedStatus = pathEq('plan', statusPath)
export const getCanceledStatus = pathEq('canceled', statusPath)
export const getDeletedStatus = pathEq('deleted', statusPath)
export const getClosedStatus = pathEq('clsd', statusPath)

export const getIsInProgressOrPackedStatus = either(getInProgressStatus, getPackedStatus)

export const getSearch = pick(['search'])
export const withoutFilters = dissoc('filter')
export const withoutSort = dissoc('sort')

export const isEqualToLead = equals('lead')
export const isEqualToProspector = equals('prsp')

export const withoutType = dissoc('type')

export const getStatusError = equals<string | undefined>('error')

export const getEndType = find(pathEq('end', ['type', 'value']))

export const getLastQueryPath = pipe(split('/'), last)

export const getOptions = pluck('options')

export const getPercent = (amount = 0, total = 0) => Number(((amount / total) * 100).toFixed(2)) || 0

export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj)),
)

export const getTranslate = curry((main: string, t: TFunction, key: string, options = {}) => {
  return t(`${main}:${key}`, options)
})

export const isApiError = (value: unknown, status: number | null = null): value is HttpResponseError => {
  status && console.log('-status-', status)
  return checkTypeApiError(value)
}

export const getUniqueID = (length = 3): string => {
  const chr4 = () => Math.random().toString(16).slice(-4)
  return [...Array(length)].reduce((acc) => acc + `${chr4()}`, '')
}

export const getStatusFromValue = (
  value: SelectItem,
): {
  statusValue: TStatusItemVariants
  label: string
} | null => {
  if (!value) {
    return null
  }

  return typeof value === 'object'
    ? {
        statusValue: value.value as TStatusItemVariants,
        label: value.label,
      }
    : {
        statusValue: value,
        label: value,
      }
}

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',')
  if (arr.length < 2) {
    return undefined
  }
  const mimeArr = arr[0].match(/:(.*?);/)
  if (!mimeArr || mimeArr.length < 2) {
    return undefined
  }
  const mime = mimeArr[1]
  const buff = Buffer.from(arr[1], 'base64')

  return new File([buff], filename, { type: mime })
}

export const getConvertedUploadImgToObj = (
  array: ImageUploadItem[],
  field = 'images',
): Record<string, string[] | File[]> => {
  return { [field]: array.map((item) => item.extra || item.url) }
}

export const getInfiniteList = pipe(map(prop('items') as any), flatten)
export const getInfiniteListLength = pipe(getInfiniteList, length)

export const isGoogleNavigator = pathEq('google', ['navigator', 'value'])

export const getActionsWithoutHidden = reject((item: SwipeActionType) => item?.isHidden || false)

export const createCustomerPhone = pipe(
  evolve({ phonePrefix: prop('label') }) as any,
  props(['phonePrefix', 'phoneNumber']),
  filter(complement(isNil)),
  join(''),
)

export const mapItemWithIcon = (item?: SelectItem, locale?: string): SelectItem => {
  const localeItem = countryIcons.find((el) => !!locale && el.value === locale)
  return item
    ? {
        ...item,
        ...(localeItem?.icon && {
          icon: localeItem.icon,
        }),
      }
    : null
}

export const mapCountriesToIcons = (items: SelectItem[]): SelectItem[] =>
  items.map((item) => mapItemWithIcon(item, item?.value))

export const snakeCase = pipe(split(REGEXP_UPPER_LETTER), join('_'), toLower)
export const dashCase = pipe(split(REGEXP_UPPER_LETTER), join('-'), toLower)

export const getPixelPositionOffset = (width = MAX_MARKER_WIDTH, height = 34) => ({ x: -(width / 2), y: -height })

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))
