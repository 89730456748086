import { HttpStatusCode, ServerErrorFieldFormatType, ServerErrorFormatType } from 'src/models'

export const getErrString = (items: any[] = []): string => {
  interface IErrItem {
    title: string
    desc: string
  }

  const getMessage = (v: IErrItem): string => `${v.title}. ${v.desc}`
  const accHandler = (acc: string, v: IErrItem): string => acc + getMessage(v)
  return Array.isArray(items) ? items.reduce(accHandler, '') : ''
}

export class HttpError extends Error {
  public readonly status: HttpStatusCode
  public readonly errors?: ServerErrorFieldFormatType

  constructor(status = 0, errorShape: ServerErrorFormatType) {
    super()
    this.name = errorShape.type || 'HttpError'
    this.message = errorShape.message || ''
    this.status = status
    this.errors = errorShape?.errors ?? {}
    Object.setPrototypeOf(this, new.target.prototype)
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      status: this.status,
      errors: this.errors,
      stacktrace: this.stack,
    }
  }
}
