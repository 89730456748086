import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useCallback, useState } from 'react'
import { showCatchErrors } from 'utils/helpers'

import { parcelsKeys, useParcelsRejectMutation } from 'src/bus/parcels'

import { useToast } from 'src/hooks'

type RejectParcelCb = (id?: number) => Promise<void>

type ParcelsRejectType = {
  loadingParcelsReject: boolean
  onRejectParcel: RejectParcelCb
}

export const useParcelsReject = (): ParcelsRejectType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { onRejectParcelsMutation } = useParcelsRejectMutation()
  const showToast = useToast()

  const [isLoading, setIsLoading] = useState(false)

  const onRejectParcel = useCallback<RejectParcelCb>(
    async (id) => {
      try {
        setIsLoading(true)

        await onRejectParcelsMutation.mutateAsync({ params: { id: String(id) } })
        showToast.success({ title: t('notifications:parcel_reject') })
        await queryClient.invalidateQueries({ queryKey: parcelsKeys.all })
      } catch (error) {
        showCatchErrors(error, showToast)
      } finally {
        setIsLoading(false)
      }
    },
    [t, onRejectParcelsMutation, showToast, queryClient],
  )

  return {
    loadingParcelsReject: isLoading,
    onRejectParcel,
  }
}
