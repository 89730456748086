import { DotLoading } from 'antd-mobile'
import { forwardRef, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import InfiniteScrollComponent from 'react-infinite-scroll-component'

import { Empty, ScrollArea } from 'src/components/ui'

import { useLocalStorage } from 'src/hooks'

import { orNull, ternary } from 'src/utils'

type InfiniteScrollProps = {
  hasMore: boolean
  onFetchMore: () => void
  dataLength: number
  loadingFetch: boolean
  renderItems: () => ReactNode | null
  scrollableTarget: string
  endMessage?: ReactElement
  loader?: ReactElement | null
  empty?: ReactElement | null
  initialScrollY?: number
}

export const InfiniteScroll = forwardRef<HTMLDivElement, InfiniteScrollProps>(
  (
    {
      hasMore,
      onFetchMore,
      dataLength,
      scrollableTarget,
      loadingFetch,
      loader = null,
      empty = null,
      renderItems,
      ...rest
    },
    ref,
  ) => {
    const [scrollY, setScrollY] = useLocalStorage<number>(`${scrollableTarget}-scroll-y`, 0)
    const [isRestoring, setIsRestoring] = useState(true)

    const scrollTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

    useEffect(() => {
      const scrollArea = document.querySelector('[data-radix-scroll-area-viewport]') as HTMLElement | null
      if (!scrollArea) return

      const handleScroll = () => {
        if (isRestoring) return

        if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current)

        scrollTimeoutRef.current = setTimeout(() => {
          setScrollY(scrollArea.scrollTop)
        }, 200)
      }

      scrollArea.addEventListener('scroll', handleScroll)

      return () => {
        scrollArea.removeEventListener('scroll', handleScroll)
        if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current)
      }
    }, [setScrollY, isRestoring])

    useEffect(() => {
      if (!scrollY) {
        setIsRestoring(false)
        return
      }

      const scrollArea = document.querySelector('[data-radix-scroll-area-viewport]') as HTMLElement | null
      if (scrollArea) {
        scrollArea.scrollTo(0, scrollY)
        setTimeout(() => setIsRestoring(false), 50)
      }
    }, [scrollY])

    return (
      <ScrollArea id={scrollableTarget} ref={ref}>
        <InfiniteScrollComponent
          {...rest}
          initialScrollY={scrollY || 0}
          scrollableTarget={scrollableTarget}
          dataLength={dataLength}
          next={onFetchMore}
          hasMore={hasMore}
          loader={orNull(
            dataLength !== 0 && loadingFetch,
            <div className='py-2 text-center'>
              <DotLoading />
            </div>,
          )}
        >
          <>
            {ternary(
              dataLength === 0 && loadingFetch,
              <>{loader}</>,
              <>{ternary(dataLength, <>{renderItems()}</>, empty || <Empty />)}</>,
            )}
          </>
        </InfiniteScrollComponent>
      </ScrollArea>
    )
  },
)

InfiniteScroll.displayName = 'InfiniteScroll'
