import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useCallback, useState } from 'react'

import { FormDeleteParcelsArticleType, FormSubmitParams } from 'src/models'

import { parcelsKeys, useParcelsDeleteMutation } from 'src/bus/parcels'

import { useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, showCatchErrors } from 'src/utils'

type DeleteParcelsCb = (data: FormSubmitParams<FormDeleteParcelsArticleType>, id?: number) => void

type UseParcelsDeleteType = {
  loadingParcelsDelete: boolean
  onDeleteParcels: DeleteParcelsCb
}

export const useParcelsDelete = (): UseParcelsDeleteType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { onDeleteParcelsMutation } = useParcelsDeleteMutation()
  const showToast = useToast()
  const { setOnPageDialog } = useDialog()

  const [isLoading, setIsLoading] = useState(false)

  const onDeleteParcels = useCallback<DeleteParcelsCb>(
    async (data, id) => {
      try {
        setIsLoading(true)

        await onDeleteParcelsMutation.mutateAsync({
          data: data.values,
          params: { id: String(id) },
        })

        setOnPageDialog(GET_ENUMS.dialog.deleteArticle, false)
        showToast.success({ title: t('notifications:parcel_article_delete') })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.articles() })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.lists() })
      } catch (error) {
        showCatchErrors(error, showToast)
      } finally {
        setIsLoading(false)
      }
    },
    [setOnPageDialog, showToast, onDeleteParcelsMutation, t, queryClient],
  )

  return {
    loadingParcelsDelete: isLoading,
    onDeleteParcels,
  }
}
