import { OtlpHttpTransport } from '@grafana/faro-transport-otlp-http'
import { faro, getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'
import { W3CTraceContextPropagator } from '@opentelemetry/core'
import { useEffect } from 'react'

export default function FrontendObservability() {
  useEffect(() => {
    if (faro.api) {
      return
    }

    try {
      initializeFaro({
        url: '/api/faro',
        app: {
          name: process.env.NEXT_PUBLIC_FARO_APP_NAME || 'unknown_service:webjs',
          namespace: process.env.NEXT_PUBLIC_FARO_APP_NAMESPACE || undefined,
          version: process.env.VERCEL_DEPLOYMENT_ID || '1.0.0',
          environment: process.env.NEXT_PUBLIC_VERCEL_ENV || 'development',
        },
        instrumentations: [
          ...getWebInstrumentations(),
          new TracingInstrumentation({
            propagator: new W3CTraceContextPropagator(),
          }),
        ],
        transports: [
          new OtlpHttpTransport({
            apiKey: process.env.NEXT_PUBLIC_FARO_API_KEY,
            logsURL: '/api/faro/logs',
            tracesURL: '/api/faro/traces',
          }),
        ],
      })

      console.log('Faro initialized:', faro)
    } catch (e) {
      console.error('Faro initialization error:', e)
    }
  }, [])

  return null
}
